/**
 * profileSettings.jsx - component responsible for filling out the userStatistics/surveys
 * document in firestore. Presents a form of questions from year to year that are then validated
 * and written to firestore
 * Author: Benni Delgado & CJ Larsen; Thomas Weiss & Roger Palmenberg
 * Arizona Institute for Resilience
 */
import React from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import states from '../data/states.json';
import firebase from 'firebase/app';

const CHECKBOXES = [
    { name: "Daily weather forecasts" },
    { name: "7-10 day forecasts" },
    { name: "10-14 day forecasts" },
    { name: "Monthly climate forecasts" },
    { name: "Seasonal climate forecasts" }
];

export default class ProfileSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first: false,
            firstForm: false,
            username: "",
            form: {
                username: "",
                state: "",
                yearsInSouthwest: "",
                mostExperienceCity: "",
                levelOfExperience: "",
                interestInMonsoon: "",
                forecastFrequencyPerWeek: "",
                wholeSeasonForecast: "",
                forecastConsults: {
                    "Daily weather forecasts": false,
                    "7-10 day forecasts": false,
                    "10-14 day forecasts": false,
                    "Monthly climate forecasts": false,
                    "Seasonal climate forecasts": false
                }
            },
            currYear: String((new Date()).getUTCFullYear()),
            needsUpdate: false
        }

        document.querySelector("body").scrollTo(0, 0);
    }

    /**
     * Function finds and returns the username for a user by looking in previous seasons' estimate data and userStatistics
     * Note: userStatistics is no longer being used, but is referenced a few times to look through old data
     * @returns username
     */
    async getUsername() {
        const { firestore } = this.props;
        const { currYear } = this.state;
        const { uid } = this.props.user;
        const startingYear = 2021;

        const users = firestore.collection('users');
        const season = firestore.collection('season');

        var username = null;

        let user = await users.doc(uid).get();
        if(user.exists){
            username = user.data().username;
            return username;
        }

        for(let i = currYear; i >= startingYear; i--){
            try {
                let user = await season.doc(i).collection('estimates').doc(uid).get();
                if(user.exists){
                    username = user.data().username;
                    break;
                }
            } catch(e) {
                console.error(e)
            }
        }

        if(username == null){
            //check old survey table for the uid
            let user = await firestore.collection('userStatistics').doc(uid).get();
            if(user.exists){
                username = user.data().username;
            }
        }

        if(username != null){
            // user does not exist in table, but does have a past username
            // need to add them
            users.doc(uid).set({username: username}, {merge: true});
        }

        return username;
    }

    /**
     * Function will check if there is already survey questions for the user
     * if state is updated to represent the answers
     */
    async componentDidMount() {
        //make a connection to firestore
        const { currYear } = this.state;
        const { firestore } = this.props;
        const { uid } = this.props.user;

        //connect to the db
        //In case we need to send out a survey mid-season, we decided this db structure is the cleanest method
        var surveys = firestore.collection('surveys').doc(currYear).collection('startSeason');

        const username = await this.getUsername();

        const defaultDoc = {
            username: username,
            state: "",
            yearsInSouthwest: "",
            mostExperienceCity: "",
            levelOfExperience: "",
            interestInMonsoon: "",
            forecastFrequencyPerWeek: "",
            wholeSeasonForecast: "",
            forecastConsults: {
                "Daily weather forecasts": false,
                "7-10 day forecasts": false,
                "10-14 day forecasts": false,
                "Monthly climate forecasts": false,
                "Seasonal climate forecasts": false
            }
        }

        try {
            //see if there is a document already
            const snap = await surveys.doc(uid).get();
            //if the doc exists destructure and fill out the form
            if(username){
                if (snap.exists) {
                    var data = snap.data();
                } else {
                    var data = defaultDoc;
                }
                // destructure the firestore record into the expected object
                // so we can check for missing fields.
                const userData = {
                    username: username,
                    state: data.state ?? "",
                    yearsInSouthwest: data.yearsInSouthwest ?? "",
                    mostExperienceCity: data.mostExperienceCity ?? "",
                    levelOfExperience: data.levelOfExperience ?? "",
                    interestInMonsoon: data.interestInMonsoon ?? "",
                    forecastFrequencyPerWeek: data.forecastFrequencyPerWeek ?? "",
                    wholeSeasonForecast: data.wholeSeasonForecast ?? "",
                    forecastConsults: data.forecastConsults ?? {
                        "Daily weather forecasts": false,
                        "7-10 day forecasts": false,
                        "10-14 day forecasts": false,
                        "Monthly climate forecasts": false,
                        "Seasonal climate forecasts": false
                    },
                };

                //set the state forces re-render to fill out the form
                //NOTE: only username is garunteed to be not undefined
                this.setState({
                    username: username,
                    form: userData,
                    needsUpdate: userData.wholeSeasonForecast === "",
                });
            } else {
                // first time user, need to create username
                this.setState({ first: true, firstForm: true });
            }

        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Sets the state of the changed object
     * @param {Event Object} e -- change event 
     */
    handleChange = (e) => {
        //special case for checkboxes
        if (e.target.id.includes("checkbox")) {
            const form = {
                ...this.state.form,
                forecastConsults: {
                    ...this.state.form.forecastConsults,
                    [e.target.id.slice(9)]: e.target.checked
                }
            }
            this.setState({ form })

        } else {
            const form = { ...this.state.form, [e.target.id]: e.target.value }
            this.setState({ form });
        }

    }

    /**
     * called when user is setting their username. checks if user name is already
     * taken, if not it will grant them the user name and create a document for
     * the user in season/{year}/estimates collection
     */
    handleUserSubmit = async () => {
        //connect to userStatistics
        const { firestore } = this.props;
        const { uid } = this.props.user;
        const { username, currYear } = this.state;

        // initialize structure in firestore
        const seasonSurvey = firestore.collection('surveys').doc(currYear).collection('startSeason');
        const estimatesRef = firestore.collection('season').doc(currYear).collection('estimates');
        const userStats = firestore.collection('userStatistics');
        const users = firestore.collection('users');

        //get the username the user is trying to set
        try {
            //see if anyone else has the username thier trying to set
            let usersQuery = await users.where('username', '==', username).get();
            let query = await userStats.where('username', '==', username).get();
            if (!query.empty && !usersQuery.empty) {
                //tell the user to pick something else
                this.props.toast("Username is Taken!", "info");
                return;
            } else {
                //otherwise create the username
                await users.doc(uid).set({
                    username: username,
                }, { merge: true });

                this.props.toast("Username Set!", "success");
                //allow the user to then interact with the rest of the app
                const form = {
                    ...this.state.form,
                    username: username
                }
                this.setState({ form, first: false });
            }
        } catch (e) {
            //something went wrong error it and tell the user
            console.error(e);
        }

        try {
            // default doc set up (copied form dashboard.jsx)
            // NOTE: no cities are set by default, they are to added when
            //       the user submits an estimate for that month/city
            await estimatesRef.doc(uid).set({
                uid: uid,
                username: username,
                year_points: 0,
                july: {
                    month_points: 0,
                },
                august: {
                    month_points: 0,
                },
                september: {
                    month_points: 0,
                }
            });

        } catch (e) {
            //TODO: add better error catching
            //if there was an error throw a generic error message
            this.props.toast('Error Setting Up Profile', 'error');
            console.error(e);
        }
    }

    /**
     * Submits the form to firestore
     * @param {Submit Object} e -- event
     */
    handleFormSubmit = async (e) => {
        e.preventDefault();

        //form data
        const { form } = this.state;
        //user id from props
        const { uid } = this.props.user;
        //grab the firestore object
        const { firestore } = this.props;

        //connect to the db
        const survey = firestore.collection('surveys').doc(this.state.currYear).collection('startSeason');

        //try for successful db query
        try {
            await survey.doc(uid).set({
                ...form,
                // collect timestamp from user survey submission
                uid, lastModified: firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true });

            this.props.toast("Information Updated", 'success');
            this.setState({ firstForm: false });

            //this.props.changeMenu({ key: 'dashboard', name: 'Dashboard' });
            this.props.history.push('/dashboard');
        } catch (e) {
            //there must have been a problem with auth/connecting to db, throw toast
            this.props.toast('Error connecting to Firestore', 'error');
            console.error(e);

        }
    }


    render() {
        const { form, first, firstForm, username, needsUpdate } = this.state;
        return (
            <motion.div animate={{ opacity: [0, 1] }}>
                <Modal
                    show={first}
                    className="modal-custom"
                    size="lg"
                    centered>
                    <Modal.Header className="justify-content-center">
                        <Modal.Title>
                            <h4>Welcome to Fantasy Monsoon!</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4>Let's start by setting your username</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <p>Your username will be shown on the public leaderboards</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={5} className="d-flex justify-content-center">
                                <Form.Group controlId="username">
                                    <Form.Control
                                        type="input"
                                        placeholder="username"
                                        value={username}
                                        onChange={e => this.setState({ username: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    className="submit-button mt-1"
                                    type="submit"
                                    onClick={this.handleUserSubmit}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={(!first && firstForm) || needsUpdate}
                    className="modal-custom"
                    size="lg"
                    centered>
                    <Modal.Header className="justify-content-center">
                        <Modal.Title>
                            <h4>{ needsUpdate? "Welcome back! Please update your profile": "Great! Now Just Some Additional Info" }</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="justify-content-center">
                            <Col className="d-flex justify-content-center">
                                <Form>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">What is your forecast for the monsoon region for the entire monsoon season, June 15 - September 30?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="wholeSeasonForecast"
                                            value={form.wholeSeasonForecast}
                                            onChange={this.handleChange}
                                            required={true}
                                        >
                                            <option value="" disabled="true">Please select a whole season forecast</option>
                                            <option>Much Below Average</option>
                                            <option>Below Average</option>
                                            <option>Near Average</option>
                                            <option>Above Average</option>
                                            <option>Much Above Average</option>
                                            <option>Don't Know</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">What state do you currently live in?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="state"
                                            value={form.state}
                                            onChange={this.handleChange}
                                        >
                                            {states.map((d) => (
                                                <option key={d.abbreviation}>{d.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">How many monsoon seasons have you experienced while living in the southwest?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={form.yearsInSouthwest}
                                            id="yearsInSouthwest"
                                            onChange={this.handleChange}
                                        >
                                            {Array.from({ length: 102 }).map((_, i) => (
                                                i === 0 ? <option key={i}>Prefer Not to Answer</option>
                                                    : <option key={i}>{i}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">Of the following five cities, which do you have the most understanding of climate?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="mostExperienceCity"
                                            value={form.mostExperienceCity}
                                            onChange={this.handleChange}
                                        >
                                            <option>Prefer Not to Answer</option>
                                            <option>Tucson</option>
                                            <option>Phoenix</option>
                                            <option>Flagstaff</option>
                                            <option>Albuquerque</option>
                                            <option>El Paso</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">How would you rate your level of understanding of the monsoon system?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="levelOfExperience"
                                            value={form.levelOfExperience}
                                            onChange={this.handleChange}
                                        >
                                            <option>Prefer Not to Answer</option>
                                            <option>Novice</option>
                                            <option>Advanced Beginner</option>
                                            <option>Intermediate</option>
                                            <option>Advanced</option>
                                            <option>Expert</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">Is your interest in the monsoon mostly driven by curiosity or because the monsoon affects your livelihood?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="interestInMonsoon"
                                            value={form.interestInMonsoon}
                                            onChange={this.handleChange}
                                        >
                                            <option>Prefer Not to Answer</option>
                                            <option>Curiosity</option>
                                            <option>Affects Livelihood</option>
                                            <option>Neither</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="subheading-text">During the monsoon season, in a typical week, how often do you look at the weather forecast?</Form.Label>
                                        <Form.Control
                                            as="select"
                                            id="forecastFrequencyPerWeek"
                                            value={form.forecastFrequencyPerWeek}
                                            onChange={this.handleChange}
                                        >
                                            <option>Prefer Not to Answer</option>
                                            <option>Multiple times a day</option>
                                            <option>Daily</option>
                                            <option>Several times a week</option>
                                            <option>About once a week</option>
                                            <option>None</option>
                                            <option>Unsure</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Label className="subheading-text">During the monsoon season, do you consult any of the following? (select all that apply)</Form.Label>
                                    {CHECKBOXES.map((d, i) => (
                                        <div key={i} className="mb-3" onChange={this.handleChange}>
                                            <Form.Check type="checkbox" id={`checkbox-${d.name}`}>
                                                <Form.Check.Input type="checkbox" checked={this.state.form.forecastConsults[d.name]} readOnly />
                                                <Form.Check.Label className="subheading-text" >{d.name}</Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                    ))}
                                </Form.Group>
                                    <Button
                                        className="submit-button"
                                        block
                                        type="submit"
                                        onClick={this.handleFormSubmit}
                                        disabled={this.state.form.wholeSeasonForecast === ""}
                                    >
                                        { this.state.form.wholeSeasonForecast === "" ? "Please make a forecast for the whole monsoon season": "Submit"}
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">

                        </Row>
                    </Modal.Body>
                </Modal>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col className="d-flex pt-3 pb-3 mb-5 justify-content-center card-large">
                            <Form>
                                <Form.Group>
                                    <Form.Label className="subheading-text">What is your forecast for the monsoon region for the entire monsoon season, June 15 - September 30?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="wholeSeasonForecast"
                                        value={form.wholeSeasonForecast}
                                        onChange={this.handleChange}
                                        style={{color:'#333'}}
                                        disabled={true}
                                    >
                                        <option>{form.wholeSeasonForecast}</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">Username</Form.Label>
                                    <Form.Control
                                        type="input"
                                        placeholder="username"
                                        id="username"
                                        // users can no longer edit their username mid-season
                                        value={username}
                                        disabled={true}
                                        className="bg-secondary text-white"
                                        
                                    />
                                    <small className="form-text text-white">Your username cannot be changed.</small>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">What state do you currently live in?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="state"
                                        value={form.state}
                                        onChange={this.handleChange}
                                    >
                                        {states.map((d) => (
                                            <option key={d.abbreviation}>{d.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">How many monsoon seasons have you experienced while living in the southwest?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={form.yearsInSouthwest}
                                        id="yearsInSouthwest"
                                        onChange={this.handleChange}
                                    >
                                        {Array.from({ length: 102 }).map((_, i) => (
                                            i === 0 ? <option key={i}>Prefer Not to Answer</option>
                                                : <option key={i}>{i}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">Of the following five cities, which do you have the most understanding of climate?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="mostExperienceCity"
                                        value={form.mostExperienceCity}
                                        onChange={this.handleChange}
                                    >
                                        <option>Prefer Not to Answer</option>
                                        <option>Tucson</option>
                                        <option>Phoenix</option>
                                        <option>Flagstaff</option>
                                        <option>Albuquerque</option>
                                        <option>El Paso</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">How would you rate your level of understanding of the monsoon system?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="levelOfExperience"
                                        value={form.levelOfExperience}
                                        onChange={this.handleChange}
                                    >
                                        <option>Prefer Not to Answer</option>
                                        <option>Novice</option>
                                        <option>Advanced Beginner</option>
                                        <option>Intermediate</option>
                                        <option>Advanced</option>
                                        <option>Expert</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">Is your interest in the monsoon mostly driven by curiosity or because the monsoon affects your livelihood?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="interestInMonsoon"
                                        value={form.interestInMonsoon}
                                        onChange={this.handleChange}
                                    >
                                        <option>Prefer Not to Answer</option>
                                        <option>Curiosity</option>
                                        <option>Affects Livelihood</option>
                                        <option>Neither</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="subheading-text">During the monsoon season, in a typical week, how often do you look at the weather forecast?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="forecastFrequencyPerWeek"
                                        value={form.forecastFrequencyPerWeek}
                                        onChange={this.handleChange}
                                    >
                                        <option>Prefer Not to Answer</option>
                                        <option>Multiple times a day</option>
                                        <option>Daily</option>
                                        <option>Several times a week</option>
                                        <option>About once a week</option>
                                        <option>None</option>
                                        <option>Unsure</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="subheading-text">During the monsoon season, do you consult any of the following? (select all that apply)</Form.Label>
                                    {CHECKBOXES.map((d, i) => (
                                        <div key={i} className="mb-3" onChange={this.handleChange}>
                                            <Form.Check type="checkbox" id={`checkbox-${d.name}`}>
                                                <Form.Check.Input type="checkbox" checked={this.state.form.forecastConsults[d.name]} readOnly />
                                                <Form.Check.Label className="subheading-text" >{d.name}</Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                    ))}
                                </Form.Group>
                                <Button
                                    className="submit-button"
                                    block
                                    type="submit"
                                    onClick={this.handleFormSubmit}
                                >
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        )
    }
}
