/**
 * home.jsx: Landing page for the website. Gives a brief overview of the game
 *           and uses firebase's auth to verify the user as well.
 * Author: Benni Delgado and CJ Larsen
 * Arizona Institute for Resilience
 */

import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import $ from "jquery";
//media
import MonsoonImgOne from "../media/monsoon-img-1.jpg";
import MonsoonImgThree from '../media/monsoon-img-3.jpg';
import OverviewImage from "../media/overview-screenshot.png";
import HowItWorksImg from "../media/estimate-desc.png";
import ScoringImage from "../media/curve-desc.png";
import GroupImage from "../media/group-img.png";
import Footer from "../components/footer";

export default function Home(props) {
    useEffect(() => {
        // event listener to resize the image 
        window.addEventListener('resize', () => {
            $(".jumbotron").css({ height: window.height + "px" });
        });


    }, []);

    const [showModal, setShowModal] = useState(props.protectedRoute ? true : false);

    const { user } = props;

    const today = new Date();
    // jan is 0 so +1 to make it 1. We always want the next month for forecasting.
    const mm = today.getMonth() + 1;
    const currYear = String(today.getUTCFullYear());

    return (
        <>
            <main className="home">
                <NavBar user={user} signIn={props.signIn} history={props.history} />

                {/* Instruction Pages */}
                <Container fluid className="jumbotron jumbotron-fluid" style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${MonsoonImgOne})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "95vh",
                    zIndex: -1,
                    top: 0,
                    bottom: 0
                }}>
                    <Container className="py-5 my-5">
                        { mm < 6 ? <div className="d-block text-center mb-4">
                            <div className="alert alert-primary text-center d-inline-block">The {currYear} season of Monsoon Fantasy is starting in June!</div>
                        </div> : null }
                        <h1 className="text-center display-3 fw-bold">Southwest Monsoon Fantasy Forecasts</h1>
                    </Container>
                    <svg className="arrows">
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </Container>

                {/* Purpose */}
                <Container id="purpose" className="home-section col-xxl-8 px-4">
                    <Row className="flex-lg-row-reverse align-items-center g-5 py-5">
                        <Col sm={12} lg={6}>
                            <img className="d-block border shadow-lg mx-lg-auto img-fluid" alt="monsoon-two"
                                src={MonsoonImgThree}
                                width="700" height="500"
                            />
                        </Col>
                        <Col lg={6}>
                            <h2 className="display-5 fw-bold lh-1 mb-3">
                                Purpose of the Game
                            </h2>
                            <p className="lead">
                                Every year the most anticipated weather words are spoken: “what will the monsoon be like?”
                            </p>
                            <p className="lead">
                                Here’s your chance to use your experience and be a forecaster. At the Arizona Institute for Resilience we have been talking about the monsoon for decades. As climate scientists and people interested in the weather, we created a game where players make monsoon forecasts, score points based on them, and compete with others.
                            </p>
                        </Col>
                    </Row>
                </Container>

                {/* Overview */}
                <Container id="overview" className="home-section col-xxl-8 px-4 my-5 py-5">
                    <Row className="flex-lg-row-reverse align-items-center g-5 py-5">
                        <Col sm={12} lg={6}>
                            <img className="d-block mx-lg-auto img-fluid" alt="monsoon-two"
                                src={OverviewImage}
                                width="700" height="500"
                            />
                        </Col>
                        <Col lg={6}>
                            <h2 className="display-5 fw-bold lh-1 mb-3">
                                Game Overview
                            </h2>
                            <p className="lead">
                                In Monsoon Fantasy Forecasts, players estimate the total monthly precipitation at each of the five major cities in the U.S. Southwest Monsoon region: Tucson, Phoenix, Flagstaff, Albuquerque, and El Paso. Points are awarded each month depending on the accuracy of the estimate compared to the actual observed rainfall. The goal is to accumulate the most points over the July, August, September period.
                            </p>
                        </Col>
                    </Row>
                </Container>

                {/* How it works */}
                <Container id="works" className="home-section col-xxl-8 px-4 my-5 py-5">
                    <Row className="flex-lg align-items-center g-5 py-5">
                        <Col sm={12} lg={6}>
                            <img className="d-block border shadow-lg mx-lg-auto img-fluid" alt="monsoon-two"
                                src={HowItWorksImg}
                                width="700" height="500"
                            />
                        </Col>
                        <Col lg={6}>
                            <h2 className="display-5 fw-bold lh-1 mb-3">
                                How it Works
                            </h2>
                            <p className="lead">
                                You make forecasts of the total rainfall amount in a one-month period for each of July, August, and September. You make forecasts each month at five cities in the Southwest. You must make your forecasts prior to the first of each month (for example, you must make forecasts for July by June 30 at 11:59 PM; for August by July 31 at 11:59 PM, and for September by August 31 at 11:59 PM). You score points based on the riskiness and accuracy of your estimates compared to the actual rainfall. You compete against all other players.
                            </p>
                            <p className="lead">
                                The top scoring player for each month (July, August, and September) will receive an Amazon gift card with a value of $100. The top three scoring players for the entire season will receive Amazon gift cards with values of $300, $200, and $100, respectively.
                            </p>
                        </Col>
                    </Row>
                </Container>

                {/* Scoring */}
                <Container id="point" className="home-section col-xxl-8 px-4 my-5 py-5">
                    <Row className="flex-lg-row-reverse align-items-center g-5 py-5">
                        <Col sm={12} lg={6}>
                            <img className="d-block border rounded shadow-lg mx-lg-auto img-fluid" alt="monsoon-two"
                                src={ScoringImage}
                                width="700" height="500"
                            />
                        </Col>
                        <Col lg={6}>
                            <h2 className="display-5 fw-bold lh-1 mb-3">
                                Scoring
                            </h2>
                            <p className="lead">
                                Points are scored each month and for each city, with the winner determined by the total points accumulated over the three months and five cities. For each month and each city, points are calculated by considering the “riskiness” of the forecast and its accuracy. The risk of the forecast depends on how likely it is to occur based on the historical data. Less likely estimates are those that occur further from the average. Riskier forecasts can score more points. The scoring also depends on the accuracy of the forecast. The more accurate the forecast is, the higher the percentage of the maximum points the player receives. More technical details on scoring can be found <a className="scoring-link" href="/scoringdetails">here</a>.
                            </p>
                        </Col>
                    </Row>
                </Container>

                {/* Group Description */}

                <Container id="group" className="home-section col-xxl-8 px-4 my-5 py-5">
                    <Row className="flex-lg align-items-center g-5 py-5">
                        <Col sm={12} lg={6}>
                            <img className="d-block border rounded shadow-lg mx-lg-auto img-fluid" alt="group system screenshot"
                                src={GroupImage}
                                width="700" height="500"
                            />
                        </Col>
                        <Col lg={6}>
                        <h2 className="display-5 fw-bold lh-1 mb-3">Groups</h2>
                        <p className="lead">Invite friends to play within your group and compare scores outside of the main game leaderboard. Group names are randomly generated, and you can share your invite code with as many players as you'd like. Create or request to join a group today to ensure bragging rights amongst your friends!</p>
                        <p className="lead">Login and visit the <a href="/groups" className="scoring-link">groups page</a> for more information!</p>
                        </Col>
                    </Row>
                </Container>

                {/* Get Started */}
                <Container className="home-section px-4 pb-5 mb-5 text-center">
                    <Container className="py-5">
                        <h2 className="display-4 fw-bold d-block">Let's Get Started</h2>
                        <Col lg={6} className="mx-auto">
                            <p className="fs-5 mb-4 lead">
                                Click the button below to create an account or log in to an existing one to play!
                            </p>

                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <Button size="lg" block className="login-button" onClick={props.signIn}>
                                    {user ? (
                                        "Play Fantasy Monsoon"
                                    ) : (
                                        "Log In"
                                    )}
                                </Button>
                            </div>
                        </Col>
                    </Container>
                </Container>

                {/* Modal Popup */}
                <Modal show={showModal} animation={false} backdrop="static">
                    <Modal.Header className="text-white">
                        <Modal.Title><h2 className="h4">Authentication Required</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>You need to be logged into your account before you can access the requested page.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="secondary-button btn btn-secondary" onClick={() => { setShowModal(false); props.history.push('/home'); } }>Close</button>
                        <button className="submit-button btn btn-primary" onClick={() => props.signIn()}>Sign In</button>
                    </Modal.Footer>
                </Modal>

            </main>

            <Footer />
        </>
    )
}


