/**
 * App.js: The main page for the application, initalizing all firebase,  ui, and
 *         css components
 * Author: Benni Delgado
 * Arizona Institute for Resilience
 */
//components
import { React, useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from './pages/home';
//media
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
//pages
import ProtectedPage from './pages/protected.jsx';
import Leaderboards from './pages/leaderboard';
import ScoringDesc from './pages/scoring';
import Terms from './pages/terms';

//firebase stuff
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import { useAuthState } from 'react-firebase-hooks/auth';
import CREDS from './creds.json';

//initalize firebase
firebase.initializeApp(CREDS);

//create auth objects
const auth = firebase.auth();
const firestore = firebase.firestore();
// const analytics = firebase.analytics();


//start browser history for routing
const history = createBrowserHistory();

/**
 * App creates the router, and handles auth.
 * NOTE: Auth is handled here because routing browser history and passing props
 * gets convoluted when working with different components that are routed to the
 * root ("/") path.
 */
function App() {
    //hook for user auth and page switching
    const [user, loading] = useAuthState(auth);

    // indicates if a user is logged in
    const [loggedIn, setLoggedIn] = useState(false);
    // indicates if still waiting on firebase to give us an auth state
    const [checkingStatus, setCheckingStatus] = useState(true);

    // this is essentially a listener that waits for firebase auth to report
    // a change in login status
    // might be a way to hook this to the useAuthState() line above, but
    // i think it makes more sense to hook into the actual auth here
    // inspiration from:
    // https://stackoverflow.com/questions/65505665/protected-route-with-firebase
    useEffect(() => {
            auth.onAuthStateChanged((user) => {
                if(user){
                    setLoggedIn(true);
                }
                setCheckingStatus(false);
            })
    }, []);

    /**
     * A component to protect user authenticated routes
     * @param {String} component -- component key to show on protected.jsx
     *                              (found in currentMenu object)
     */
    const ProtectedRoute = ({ component, ...rest }) => {
        if(checkingStatus){
            return(<Spinner />)
        } else {
            if(loggedIn){
                return(<Route {...rest} render={props =>(
                    <ProtectedPage 
                        user={auth.currentUser} 
                        signOut={signOut} 
                        time={firebase.firestore.FieldValue} 
                        firestore={firestore} 
                        history={history} 
                        component={component}
                        params={rest.computedMatch.params}
                    />
                )} />)
            } else {
                return(
                <Route {...rest} render={
                        props => 
                        <Home 
                            {...props} 
                            protectedRoute={true} 
                            user={user} 
                            signIn={signInWithGoogle} 
                        />
                    } 
                />)
            }
        }
    }


    //authenticate
    const signInWithGoogle = () => {
        if (!user) {
            const provider = new firebase.auth.GoogleAuthProvider();
            auth.signInWithPopup(provider);
        } else if (loading) {
            return (<Spinner />)
        } else {
            //push the page on to the stack
            history.push('/dashboard');
        }
    }

    const signOut = () => {
        auth.signOut().then(() => history.push('/'));
    }


    return (
        <Router history={history}>
            <div className="App">
                <Switch>
                    <ProtectedRoute component='dashboard' path='/dashboard' />
                    <ProtectedRoute component='estimate' path='/estimate' exact />
                    <ProtectedRoute component='estimate' path='/estimate/:city' />
                    <ProtectedRoute component='leaderboards' path='/leaderboard' />
                    <ProtectedRoute component='surveys' path='/surveys' />
                    <ProtectedRoute component='profile' path='/profile' />
                    <ProtectedRoute component='scoring' path='/scoring' />
                    <ProtectedRoute component='digest' path='/digest' />
                    <ProtectedRoute component='outlooks' path='/outlooks' />
                    <ProtectedRoute component='updates' path='/updates' />
                    <ProtectedRoute component='resources' path='/resources' />
                    <ProtectedRoute component='group' path='/groups' exact />
                    <ProtectedRoute component='grouplist' path='/groups/leaderboards' exact />
                    <ProtectedRoute component='group' path='/groups/:group' exact />
                    <ProtectedRoute component='groupleaderboard' path={['/groups/:group/leaderboard/compare/:compare','/groups/:group/leaderboard' ]} />
                    <Route path="/home" render={props => <Home {...props} user={user} signIn={signInWithGoogle} />} />
                    <Route path='/leaderboards' render={props => <Leaderboards {...props} public={true} user={auth.currentUser} firestore={firestore} signIn={signInWithGoogle} />} />
                    <Route path='/scoringdetails' render={props => <ScoringDesc {...props} public={true} user={auth.currentUser} firestore={firestore} signIn={signInWithGoogle}/>} />
                    <Route path='/terms' render={props => <Terms {...props} public={true} user={auth.currentUser} firestore={firestore} signIn={signInWithGoogle}/>} />

                    <Route exact path="/" render={() => history.push('/home')} />
                </Switch>
            </div >
        </Router>
    );
}




export default App;
