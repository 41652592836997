/**
 * gameUpdates.jsx - This is a simple element that contains updates that need
 * to be communicated to players. Just add whatever HTML you need to the render
 * function.
 * Arizona Institute for Resilience
 * Author: Leland Boeman; updated by Roger Palmenberg
 */

import React from 'react';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { Row, Container, Col, Form, Spinner } from 'react-bootstrap';
import { DigestHistogram } from './visualizations';

export default class GameUpdates extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <motion.div animate={{ opacity: [0, 1] }} className="card-large">
            <Container fluid>
            <Row className="d-flex align-content-left" style={{ paddingTop: "2%"}}>
              <Col>
                <h4>June 15, 2024 - Season 4 Updates</h4>
                <p>
                  Welcome back to the fourth season of Monsoon Fantasy! We are so excited 
                  for you to experience all the wonderful additions and changes we have 
                  made to the website. 
                </p>
                <ol className="game-updates-list" style={{ color: "#FFF"}}>
                  <li>
                    <p>
                      Groups are a fun extension to the game this year. Players can create 
                      up to five groups and invite as many of their friends, coworkers, or 
                      family members to join them using invite codes. Groups are an 
                      excellent way to have friendly competition between others interested 
                      in the Southwestern Monsoon. There is also group leaderboard 
                      functionality within groups, so players can see how their scores
                      compare with other players in their groups for each month/city. 
                    </p>
                  </li>
                  <li>
                    <p>
                      This year, $900 worth of prizes are up for grabs! $300, $200, and $100 Amazon 
                      gift cards will be given to the 1st-place, 2nd-place, and 3rd-place finishers, 
                      respectively. Additionally, top-scoring players for each month are eligible to 
                      earn a $100 Amazon gift card.
                    </p>
                  </li>
                  <li>
                    <p>
                      Monsoon Resources have been added to this website to assist players in 
                      making predictions and better understanding the Southwestern Monsoon. 
                      Topics for resources are color-coordinated at the top of the page and 
                      can be filtered by users based on their curiosities.
                    </p>
                  </li>
                  <li>
                    <p>
                      Outlooks is a new page on this year's website, too! Players can utilize 
                      drop-down tables filled with information from the National Weather Service 
                      on the probabilities of wet, dry, or normal rainfall conditions for certain 
                      date ranges.
                    </p>
                  </li>
                  <li>
                    <p>
                    Many other subtle quality-of-life changes (e.g., sign in pop-ups) have been 
                    made to help streamline players' experience while playing the game.
                    </p>
                  </li>
                </ol>
              </Col>
            </Row>
            </Container>
            <Container fluid>
              <Row className="d-flex align-content-left" style={{ paddingTop: "2%"}}>
                <Col>
                  <h4>June 15, 2023 - Season 3 Updates</h4>
                  <p>
                    Welcome back to the third season of Monsoon Fantasy. There have
                    been a few updates to the game this season.
                  </p>
                  <ol className="game-updates-list" style={{ color: "#FFF"}}>
                    <li>
                      <p>
                          The scoring algorithm has been updated to fix an issue
                          where players were more likely to receive points for forecasts
                          dryer than the mean. In short, the old method for determining
                          the accuracy of your forecast meant that forecasts on the
                          dry side could receive points for values that accounted for
                          70 percent of the historical values seen, where wetter forecasts
                          could cover as little as 10%.
                      </p>
                      <p>
                          The algorithm used for determining the maximum points has not
                          been changed, but the way that accuracy is judged has been
                          modified. We've replaced the use of the standard deviation with a
                          fixed percentile range in the accuracy algorithm. This
                          means that the ranges for which you will score points are
                          now narrower on the dry side, and wider on the wet side but
                          no matter the geuss the range should cover approximately
                          the same portion of historical values.
                      </p>
                      <p>
                          For more details, see the updated Scoring Description page.
                      </p>
                    </li>
                    <li>
                      We've added a Monsoon Resources page, with links to resources
                      for players to gather information about the monsoon.
                    </li>
                    <li>
                      The Dashboard has been updated to be more compact. If
                      you have the screen real estate, you should now be able to
                      see the forecast tiles, quick looks, and monthly plots all
                      at once.
                    </li>
                  </ol>
                </Col>
              </Row>
            </Container>

            <Container fluid>
              <Row className="d-flex align-content-left" style={{ paddingTop: "2%"}}>
                <Col>
                  <h4>July 29, 2022</h4>
                  <p>
                    The scoring algorithm used Log Base 10 instead of the Natural Log to calculate
                    the scores, causing an undervaluing of the semi-risky forecasts. The bug also
                    affected the values for the possible points earned that is shown on the pages
                    where players submit the forecasts. We updated the scoring algorithm.
                  </p>
                  <p>
                    As a consequence of this bug, we implemented 3 changes, described in an email
                    send at 6:00 am MST on August 1, 2022.</p>
                  <ol className="game-updates-list" style={{ color: "#FFF"}}>
                    <li>
                      We extended the deadline to submit August forecasts through the end of Monday,
                      August 1st.
                    </li>
                    <li>
                      If the scoring changes affect the final results so that different players
                      are in the top 3 prize positions, we will award up to two prizes for each
                      position. For a player who finishes in the top 3 either way, we will award
                      the higher of the two final positions.
                    </li>
                    <li>
                      We added this page to the Website on August 1st, 2022. This page will
                      describe changes and updates to the game.
                    </li>
                  </ol>
                  <p>
                    The game team is playing (because it is fun!) but are not eligible for
                    prizes.
                  </p>
                </Col>
              </Row>
          </Container>
        </motion.div>
        )
    }
}
