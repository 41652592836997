/**
 * 
 * Author: Thomas Weiss
 * Arizona Institute for Resilience
 */
import React from 'react';
import { Row, Container, Col, Table, Spinner } from 'react-bootstrap';
import { Collapse } from './wrappers';

const CITY_KEYS = ['tucson', 'phoenix', 'flagstaff', 'el_paso', 'albuquerque'];

const CITIES_MAP = {
    'tucson': 'Tucson',
    'phoenix': 'Phoenix',
    'flagstaff': 'Flagstaff',
    'el_paso': 'El Paso',
    'albuquerque': 'Albuquerque'
}

export default class AIDetails extends React.Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const currMonth = today.getMonth() + 1; // +1 because it is 0 based
        var shownMonths = [];
        if(currMonth == 7){
            // july, show july
            shownMonths = ['July'];
        } else if(currMonth == 8){
            // august
            shownMonths = ['July', 'August'];
        } else if(currMonth >= 9){
            // september
            shownMonths = ['July', 'August', 'September'];
        }

        this.state = {
            currMonth: currMonth,
            shownMonths: shownMonths
        }

        document.querySelector("body").scrollTo(0, 0);
    }

    render(){
        return(
            <Container className="card-large mt-3 mx-auto p-4">
                <Row className="mb-4">
                    <Col className="text-white">
                        <h3 className="h4 mb-4">
                            Prompt Given
                        </h3>
                        <p>
                        "Predict [month] rainfall totals for Tucson, Phoenix, Flagstaff, Albuquerque, and El Paso using historical data.
                        </p>
                        <p>For each city:</p>
                        
                        <ol className="terms-list">
                            <li className="my-0">1. Provide a specific prediction between 0-8 inches</li>
                            <li className="my-0">2. Explain your reasoning, citing relevant historical patterns and trends</li>
                            <li className="my-0">3. Assign a confidence level (low/medium/high) with justification</li>  
                        </ol>
                        <p>
                        Base predictions on factors like:
                        </p>
                        <ol className="terms-list">
                            <li className="my-0">- Average [month] rainfall</li> 
                            <li className="my-0">- Year-to-year variability</li>
                            <li className="my-0">- Long-term trends</li>
                            <li className="my-0">- Notable past events</li>
                        </ol>
                        <p>
                        Provide concise explanations focused on key data points that informed each prediction."
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-white">
                        <h3 className="h4 mb-4">
                            AI Predictions
                        </h3>
                        { this.props.data.length == 0 ? 
                        <Container>
                            <Row>
                                <Col className="d-flex justify-content-center py-4">
                                    <Spinner className="d-flex justify-content-center" animation="border" variant="light" />
                                </Col>
                            </Row>
                        </Container> : 
                        this.props.data.sort((a, b) => a.username.localeCompare(b.username)).map((data) => 
                            (<Collapse title={data.username} className="px-3" collapseContainerClass="bg-dark-secondary" key={data.username}>
                                {this.state.shownMonths.map((month) => (
                                    <Collapse title={month + " Predictions"} key={month}>
                                        <Table responsive striped bordered hover variant='dark'>
                                            <thead>
                                                <tr>
                                                    <th>City</th>
                                                    <th>Prediction (in.)</th>
                                                    <th>Confidence Level</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CITY_KEYS.map((city) => (
                                                    <tr key={city}>
                                                        <td>{CITIES_MAP[city]}</td>
                                                        <td>{data[month.toLowerCase()][city]['estimate']}</td>
                                                        <td>{data[month.toLowerCase()][city]['confidence']}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Collapse>
                                ))}
                            </Collapse>))
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}