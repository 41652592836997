/**
 * estimate.jsx -- Component for placing an estimate in the game, as well as
 * separate functional components EstimateChart and HistoricalChart responsible
 * for visualizing their respective datasets.
 * Author: Benni Delgado & CJ Larsen; Thomas Weiss & Roger Palmenberg
 * Arizona Institute for Resilience
 */
import React from 'react';
import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { Container, Row, Col, Button, Table, Modal, Form } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { EstimateHistogram } from './visualizations';
import historicalcleaned from '../data/historicalcleaned.json';

// Do something smarter than importing this everywhere

const CITIES_MAP = {
    tucson: "Tucson",
    phoenix: "Phoenix",
    flagstaff: "Flagstaff",
    el_paso: "El Paso",
    albuquerque: "Albuquerque"
};
const estimateMonths = ["July", "August", "September"];

export default class Estimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currCity: this.props.info.currCity === 'El Paso' ? 'el_paso' : this.props.info.currCity.toLowerCase(),
            currMonth: !estimateMonths.includes(this.props.info.currMonth) ? "September" : this.props.info.currMonth,
            currYear: String((new Date()).getUTCFullYear()),
            max: 8,
            pointsMax: 4,
            rainData: {},
            estimate: { // for the slider ball location
                tucson: [0.0],
                phoenix: [0.0],
                flagstaff: [0.0],
                el_paso: [0.0],
                albuquerque: [0.0]
            },
            submitted: {
                tucson: { estimate: 'N/A', time: 'N/A' },
                phoenix: { estimate: 'N/A', time: 'N/A' },
                flagstaff: { estimate: 'N/A', time: 'N/A' },
                el_paso: { estimate: 'N/A', time: 'N/A' },
                albuquerque: { estimate: 'N/A', time: 'N/A' }
            },
            showMonthSurvey: false,
            postEstimateSurvey: [ //survey to appear after user submits estimates
                {
                    id: 'predominant_reason',
                    required: true,
                    questionText: 'What is predominantly influencing your forecast? (select one)',
                    options: [[`Rainfall conditions during last year's monsoon season`, `Rainfall conditions during the last month`], 'Media commentary about this monsoon season I read online', 'Technical seasonal and weather forecasts made from credible organizations', 'I am making a wild guess', 'Other', 'I prefer not to answer']
                },
            ],
            form: {} //holds the user responses
        }

        document.querySelector("body").scrollTo(0, 0);
    }

    /**
     * checks on mount to see if user has a doc for current season. if not it will
     * create a doc for them and then populate the estimate fields with their
     * estimates.
     */
    async componentDidMount() {
        //get current month and year
        const { currYear } = this.state;
        let { currMonth } = this.state;

        currMonth = this.lowerizeFirstLetter(currMonth);

        //get firestore objects
        const { firestore } = this.props;
        const { uid } = this.props.user;

        // code block to redirect to profile page if username doesn't exist or username is blank and redirects if so
        const userRef = await firestore.collection('users').doc(uid).get();
        if(!userRef.exists || (!userRef.data().username && userRef.data().username === "")){
            this.props.history.push('/profile');
        }

        const estimatesRef = firestore.collection('season').doc(currYear).collection('estimates');
        const rainStatsRef = firestore.collection('rainStatistics').doc(currYear);

        try {
            // check if user has doc in season/{year}/estimates collection
            const userDoc = await estimatesRef.doc(uid).get();

            if (!userDoc.exists) {
                // doesn't exist, need to create userDoc
                // get username from users
                const users = await firestore.collection('users').doc(uid).get();
                const username = users.data().username;

                // default doc set up
                // NOTE: no cities are set by default, they are to added when
                //       the user submits an estimate for that month/city
                await estimatesRef.doc(uid).set({
                    uid: uid,
                    username: username,
                    year_points: 0,
                    july: {
                        month_points: 0,
                    },
                    august: {
                        month_points: 0,
                    },
                    september: {
                        month_points: 0,
                    }
                });

            } else {
                // does exist! check for their estimates
                const userEstimates = userDoc.data();

                // rain data
                const rainStats = await rainStatsRef.get();
                const rainData = rainStats.data();

                this.setState({ rainData });

                Object.keys(CITIES_MAP).forEach((city) => {
                    // if city exists there is estimate, else leave default
                    if (userEstimates[currMonth].hasOwnProperty(city)) {
                        const estimate = {
                            ...this.state.estimate,
                            [city]: [userEstimates[currMonth][city].estimate]
                        }

                        const submitted = {
                            ...this.state.submitted,
                            [city]: {
                                estimate: userEstimates[currMonth][city].estimate,
                                time: userEstimates[currMonth][city].created_at.toDate().toLocaleDateString('en-US')
                            }
                        }

                        this.setState({ estimate, submitted });
                    }
                })
                this.updateScore(userEstimates[currMonth][this.state.currCity].estimate, this.state.currCity, currMonth);
            }

        } catch (e) {
            console.error(e);
        }
    }

    /**
     * function for when user submits an estimate. will updates user's doc in firestore
     * (will create the city obj if it doesn't exitst) as well as the submitted 
     * obj in state.
     * 
     * @param {*} e 
     */
    handleSubmit = async (e) => {
        //prevent default refresh
        e.preventDefault()
        //grab the estimate data
        const { currCity, currYear, estimate } = this.state;
        const { currMonth, closed } = this.props.info;

        if(closed){
            this.props.toast('Season is currently closed!', 'error');
            return;
        }

        //get firestore objects
        const { firestore } = this.props;
        const { uid } = this.props.user;

        const estimatesRef = firestore.collection('season').doc(currYear).collection('estimates');

        try {
            // doc(uid) is created (if needed) on mount, so it will always exist here
            estimatesRef.doc(uid).update({
                [`${this.lowerizeFirstLetter(currMonth)}.${currCity}`]: {
                    estimate: parseFloat(estimate[currCity][0]),
                    points: 0,
                    created_at: this.props.time.serverTimestamp()
                }
            }).then(()=>{
                //check if user has filled out the current month survey
                var monthSurveyName = `postEstimate${this.state.currMonth}`;
                const surveyRef = firestore.collection('surveys').doc(this.state.currYear).collection(monthSurveyName).doc(this.props.user.uid);
                surveyRef.get().then((doc) => {
                    if(!doc.exists){
                        //user has not filled out the survey, show the prompt
                        this.setState({showMonthSurvey: true});
                    }
                }).catch((e)=>{
                    console.error(e);
                });
            });

            const submitted = {
                ...this.state.submitted,
                [currCity]: {
                    estimate: parseFloat(estimate[currCity][0]),
                    time: (new Date()).toLocaleDateString('en-US') // just need date, not exact time
                }
            };
            this.setState({ submitted });

            //if there were no errors send a confirmation toast 
            this.props.toast('Forecast Submitted!', 'success');
        } catch (e) {
            //TODO: add better error catching
            //if there was an error throw a generic error message
            this.props.toast('Error Placing Forecast', 'error');
            console.error(e);
        }

    }

    /**
     * function to update the maxPoints for a given city/month combination, to be
     * used for the slider, viz and score table
     * 
     * @param {*} estimate 
     * @param {*} currCity 
     * @param {*} month 
     */
    updateScore = (estimate, currCity, month) => {
        const { rainData } = this.state;
        month = this.capitalizeFirstLetter(month);
        currCity = currCity === 'El Paso' ? 'el_paso' : currCity.toLowerCase();

        const stDev = rainData[CITIES_MAP[currCity]][month].stdev;
        // code taken from the google fuction
        let maxPoints;
        if (estimate === 0) {
            //log(0) is undefined so the max points will be 8
            maxPoints = 8
        } else {
            //otherwise calculate the max points
            maxPoints = -4 * Math.exp(-(Math.pow(Math.log(estimate / rainData[CITIES_MAP[currCity]][month].mean), 2)) / (2 * Math.pow((stDev / 2), 2))) + 8;
        }

        this.setState({ pointsMax: Math.round(maxPoints * 100) /100 })
    }
    /**
     * Returns the percentile of a given value through interpolation of nearest ranks.
     *
     * @param {array<number>} sample - An array of values that make up the distibution
     * @param {number} value - The value to calculate a percentile for.
     *
     * @returns {number} The quantile of the value
     */
    get_percentile_of_value(dataset, value) {
        // get the size of the dataset
        const n = dataset.length;
        // Get the left and right indexes where the value *would* be inserted. If the value
        // does not exist in the set, these indexes will be the same. If the value does exist
        // in the sample, these indexes will be separated by a value equal to the number of
        // occurrences - 1.
        let leftInsert = this.get_insertion_index(dataset, value, 'left');
        let rightInsert = this.get_insertion_index(dataset, value, 'right');

        let percentile;
        if (value === 0) {
            return 0;
        } else if (leftInsert >= n|| rightInsert === n) {
            // If the value would fall above the highest value in the sample,
            // we consider percentile is 100.
            percentile = 1;
        } else if (rightInsert === 0) {
            // if the value would fall below the lowest value in the sample,
            // percentile is 0.
            percentile = 0;
        } else {
            // The lower percentile rank is the value that comes before the
            // insertion index in the sample, in other words, the value at index - 1.
            let lowerrank = leftInsert - 1;
            // The higher percentile rank is the value that comes after the value
            // to be inserted into the set. We can use the insertion index, since the
            // act of inserting would have the effect of shifting all following indices
            // by one.
            let higherrank = rightInsert;
            //
            // Get the values in the sample at the higher and lower ranks..
            let lowValue = dataset[lowerrank];
            let highValue = dataset[higherrank];

            // Calculate the percentile of the lower and higher percentile ranks.
            let lowPercentile = lowerrank / n;
            let highPercentile = higherrank / n;

            // Calculate the the change in percentile with respect to value for interpolating
            // between the nearest ranks.
            let slope = (highPercentile - lowPercentile ) / (highValue - lowValue);
            // Calculate the difference in x (rainfall estimate) to interpolate between values.
            let delta_x = value - lowValue;
            percentile = lowPercentile + (slope * delta_x);
        }
        return percentile;
    }


    /**
     * builds the two arrs needed for the scores table. pointsArr is actual points
     * awarded (0 -> maxPoints -> 0), pointsRangeArr is the rainfall amount range
     * that will award such points.
     *
     * @param {*} estimate
     * @param {*} currCity
     * @param {*} month
     * @param {*} maxPoints
     * @returns object of points awarded and points range arrs
     */
    getPointRanges = (estimate, currCity, month, maxPoints) => {
        const { rainData } = this.state;

        if (Object.keys(rainData).length === 0) {
            return ({ 'pointsArr': [], 'pointsRangeArr': []});
        }

        const splitArr = [0, 0.25, 0.5, 0.75, 1, 0.75, 0.5, 0.25, 0];

        let pointsArr = [];
        let pointsRangeArr = [];

        for (const percent of splitArr) {
            // round to the nearest 100th
            pointsArr.push(Math.round(maxPoints * percent * 100) / 100);
        }

        const histCity = CITIES_MAP[currCity];
        const histMonth = this.capitalizeFirstLetter(month);
        const historical = Object.values(
            historicalcleaned
        ).map(x => parseFloat(x[histCity][histMonth]));

        historical.sort();
        let historicalMax = historical[historical.length - 1];
        let percentile = this.get_percentile_of_value(historical, estimate);
        if (estimate > historicalMax) {
            percentile = estimate / historicalMax;
        }
        // Estimate error limit is the percentile error at which the player scores no points.
        // e.g. a guess at the 60th percentile, with actual rain at the 76th gets 0 points.
        const estimateErrorLimit = 0.20;

        // Ratios used for multiplying with the above limit. Used to dynamically determine
        // bins instead of hard coding.
        const limitRatios = [-1, -0.75, -0.5, -0.25, 0.25, 0.5, 0.75, 1];

        // Calculate bin limits in +/- % by using the estimateError limit and limitRatios.
        // Bins are formed between index, e.g. > percentileLimits[0], between
        // percentileLimits[0] and [1] etc...
        const percentileLimits = limitRatios.map(x => x * estimateErrorLimit);

        // Find the actual percentiles at which we cut off the bins, by adding the percentile
        // limits to the estimate's percentile.
        const percentiles = percentileLimits.map(x => x + percentile);

        // Find the values, or esimates that pertain to the percentile limits.
        const limits = percentiles.map(x => d3.quantile(historical, x));
        // For each limit that corresponds to a percentile > 100, replace with
        // a limit that is the "percentile" * the max value.
        for (let i = 0; i < limits.length; i++) {
            if (percentiles[i] > 1) {
                const modifier = percentiles[i]
                limits[i] = historicalMax * modifier;
            }
        }
        // Set the string ranges for display

        // Any Values less than the minimum or, if the minimum is 0, N/A
        pointsRangeArr.push(percentiles[0] <= 0? 'N/A' : `< ${this.round(limits[0])}"`);

        // Create the point ranges
        for (let i = 0; i < limits.length -1; i++) {
            let lower = this.round(limits[i]);
            let upper = this.round(limits[i+1]);
            let lowerPercentile = percentiles[i];
            let upperPercentile = percentiles[i+1];
            if (lowerPercentile <= 0) {
                if (upperPercentile <= 0) {
                    // if lower and upper bound are less than 0, not possible, so no points
                    // can be awarded.
                    pointsRangeArr.push('N/A');
                } else {
                    // if the lower bound is less than 0, but the upper bound isn't, award
                    // points between 0 and the upper bound.
                    pointsRangeArr.push(`0" - ${upper}"`);
                }
            } else {
                // Generally, create a range between lower and upper
                pointsRangeArr.push(`${lower}" - ${upper}"`);
            }
        }
        // Any values greater than the upper limit
        pointsRangeArr.push(`> ${this.round(limits[limits.length -1])}"`);
        return ({
            'pointsArr': pointsArr,
            'pointsRangeArr': pointsRangeArr,
        });
    }
    /*
     * Gets the insertion index of a value in an array.
     *
     *  @param {array<number>} arr - The array to search into.
     *  @param {number} value - The value to insert.
     *  @param {string} side - One of 'left' or 'right', default is 'right'.
     *      Determines how to handle comparisons of like numbers. If "left" is
     *      passed, the number will be inserted before any equal values, if
     *      "right" is passed, the number will be insert after any equal
     *      values.
     */
    get_insertion_index(arr, value, side){
        if (arr.length === 0 ) {
           return 0;
        }
        // Assume right insert is desired, for which we want to iterate until the
        // value is greater than x, so our comparison should be less than or equal
        // to.
        let comp = x => value >= x;
        if (side == 'left') {
            // iterate until value is greater than x. Value will be greater than
            // or equal to all values up to that point.
            comp = x => value > x;
        }

        let i = 0;
        while (comp(arr[i]) && (i<arr.length)) {
            i++;
            if (i == arr.length) {
                break;
            }
        }
        return i;
    }

    round(num) {
        return Math.round(num * 100) / 100;
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    lowerizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    handleFormChange(e) {
        const form = {
            ...this.state.form,
            [e.target.id]: e.target.value
        }
        this.setState({ form });
    }

    handleFormSubmit(){
        var survey = {};
        for(let i = 0; i < this.state.postEstimateSurvey.length; i++){
            //for each question in survey
            if(this.state.form[this.state.postEstimateSurvey[i].id]){
                //question is answered
                survey[this.state.postEstimateSurvey[i].id] = {
                    questionText: this.state.postEstimateSurvey[i].questionText,
                    answer: this.state.form[this.state.postEstimateSurvey[i].id]
                }
            } else if (this.state.postEstimateSurvey[i].required) {
                //question not answered and required
                this.props.toast('Please answer all required questions.', 'error');
                return;
            }
        }
        const {firestore} = this.props;
        const {uid} = this.props.user;

        var monthSurveyName = `postEstimate${this.state.currMonth}`;
        const surveyRef = firestore.collection('surveys').doc(this.state.currYear).collection(monthSurveyName);
        surveyRef.doc(uid).set(
            {
                survey,
                submitted: this.props.time.serverTimestamp()
            }, { merge: true }).then(() => {
                this.props.toast('Survey submitted!','success');
                this.setState({ showMonthSurvey: false });
            }).catch((e)=>{
                console.error(e);
            });
    }

    render() {
        const { estimate, currCity, currMonth, max, submitted, pointsMax, rainData } = this.state;
        const { closed } = this.props.info;
        const { history } = this.props;

        const citiesList = Object.keys(CITIES_MAP);
        const currMonthNum = new Date(Date.parse(currMonth + " 1, 2012")).getMonth();

        const mobile = window.innerWidth < 768;
        const chartWidth = mobile ? window.innerWidth - 100 : 600;
        const chartHeight = chartWidth / 1.5;
        return (
            <>
                <motion.div animate={{ opacity: [0, 1] }} className="card-large">
                    <Container className="mb-5">
                        <Row className="mt-3 mb-1">
                            <Col className="d-flex justify-content-center" style={{ padding: 15 }}>
                                <h3><center>Distribution of Historical Rainfall for {CITIES_MAP[currCity]} in {currMonth}</center></h3>
                            </Col>
                        </Row>
                        <Row xs={1} id='rain-chart-row'>
                            <Col className="d-flex justify-content-center">
                                <EstimateHistogram
                                    width={chartWidth} // 600 - 1.5 ratio
                                    height={chartHeight} // 400
                                    estimate={estimate[currCity][0]}
                                    rainData={rainData}
                                    currCity={currCity}
                                    currMonth={currMonth}
                                    setMax={e => this.setState({ max: e })}
                                    mobile={mobile}
                                />
                            </Col>
                        </Row>
                        <Row xs={1} md={1}>
                            <Col>
                                <Row className="d-flex justify-content-center">
                                    <button
                                        className="submit-button btn btn-primary small mr-3"
                                        onClick={(e) => {
                                            this.setState({estimate: { ...this.state.estimate, [currCity]: [ Math.round((this.state.estimate[currCity][0] * 100) - 5) / 100] } });
                                            this.updateScore(Math.round((this.state.estimate[currCity][0] * 100) - 5) / 100, currCity, currMonth)
                                        }}
                                        disabled={this.state.estimate[currCity][0] <= 0}
                                    >&minus;</button>
                                    <RangeSlider
                                        value={estimate[currCity][0]}
                                        onChange={(e) => {
                                            this.setState({ estimate: { ...this.state.estimate, [currCity]: [e.target.value] } });
                                            this.updateScore(e.target.value, currCity, currMonth);
                                        }}
                                        min={0}
                                        max={max}
                                        step={0.05}
                                        variant="warning" />
                                    <button 
                                        className="submit-button btn btn-primary small ml-3"
                                        onClick={(e) => {
                                            this.setState({estimate: { ...this.state.estimate, [currCity]: [ Math.round((this.state.estimate[currCity][0] * 100) + 5) / 100] } });
                                            this.updateScore(Math.round((this.state.estimate[currCity][0] * 100) + 5) / 100, currCity, currMonth)
                                        }}
                                        disabled={this.state.estimate[currCity][0] >= max}
                                    >+</button>
                                </Row>
                                <Row className="d-flex justify-content-center">
                                    <h4>Your Forecast:&nbsp;</h4>
                                    <h4 style={{ color: '#ffcd60' }}>{estimate[currCity][0]}"</h4>
                                </Row>
                                <Row className="d-flex justify-content-center col-lg-5 mx-auto">
                                    <h5>Drag the slider to place your forecast, then hit submit to record your estimate.</h5>
                                </Row>
                                <Row className="d-flex justify-content-center mt-3">
                                    <Col xs={6} className="d-flex justify-content-center">
                                        <Button
                                            className="submit-button mb-4"
                                            block
                                            type="submit"
                                            disabled={closed || currMonthNum < 5 || currMonthNum > 8}
                                            onClick={this.handleSubmit}
                                        >
                                            Submit{estimate[currCity][0] !== submitted[currCity].estimate && submitted[currCity].estimate != 'N/A' ? ' Updated Forecast' : null}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <PointTable
                            estimate={parseFloat(estimate[currCity][0])}
                            city={currCity}
                            month={currMonth}
                            maxPoints={pointsMax}
                            getPointRanges={this.getPointRanges} />
                    </Container>
                </motion.div>
                <motion.div animate={{ opacity: [0, 1] }} className="card-large p-2">
                    <Container className="mb-5">
                        <Row className="mt-5">
                            <Col className="d-flex justify-content-center">
                                <h3>Scorecard Overview</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4 className="whitespace">Estimate Month:</h4>
                                {currMonthNum < 5 || currMonthNum > 8 ?
                                    <h4 className="capitalize" style={{ color: '#f0534d' }}>Closed for the Season!</h4>
                                    : closed ? <h4 className="capitalize" style={{ color: '#f0534d' }}>Closed for {currMonth}!</h4>
                                        : <h4 className="capitalize" style={{ color: '#3cbc74' }}>{currMonth}</h4>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <h4 className="whitespace">City:</h4>
                                <h4 style={{ color: '#3cbc74' }}>{CITIES_MAP[currCity]}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <h4 className="whitespace"><u>Submitted Forecast</u></h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <h4 className="whitespace">Forecast:</h4>
                                        <h4 style={{ color: '#3cbc74' }}>{
                                            submitted[currCity].estimate === 'N/A' ?
                                                submitted[currCity].estimate : `${submitted[currCity].estimate}"`
                                        }</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <h4 className="whitespace">Placed On:</h4>
                                        <h4 style={{ color: '#3cbc74' }}>{
                                            submitted[currCity].time
                                        }</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <h4 className="whitespace"><u>Active Forecast</u></h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <h4 className="whitespace">Forecast:</h4>
                                        <h4 style={{ color: '#3cbc74' }}>{estimate[currCity][0]}"</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row xs={1} md={2} className="d-flex justify-content-center mt-3">
                            <Col className="d-flex justify-content-center">
                                <Button
                                    className="submit-button"
                                    block
                                    type="submit"
                                    disabled={currCity === 'tucson'}
                                    onClick={() => {
                                        document.querySelector("body").scrollTo(0, 0);
                                        this.setState({ currCity: citiesList[citiesList.indexOf(currCity) - 1] })
                                        history.push('/estimate/' + citiesList[citiesList.indexOf(currCity) - 1]);
                                    }}
                                >
                                    Previous City
                                </Button>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    className="submit-button"
                                    block
                                    type="submit"
                                    disabled={currCity === 'albuquerque'}
                                    onClick={() => {
                                        document.querySelector("body").scrollTo(0, 0);
                                        this.setState({ currCity: citiesList[citiesList.indexOf(currCity) + 1] });
                                        history.push('/estimate/' + citiesList[citiesList.indexOf(currCity) + 1]);
                                    }}
                                >
                                    Next City
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </motion.div>
                <Modal show={this.state.showMonthSurvey} animate={false}>
                    <Modal.Header>
                        <Modal.Title className="text-white">
                            <h3 className="h4">Monthly Forecast Survey</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-4">
                        {this.state.postEstimateSurvey.map((q) => (
                            <>
                            <p>{q.required ? <span className="mr-1 text-danger">*</span> : null}{q.questionText}</p>
                            <Form.Control as="select" id={q.id} onChange={(e) => this.handleFormChange(e)} className="mb-3">
                                <option value="" disabled="true" selected>Please select an option...</option>
                            {q.options.map((o) => (
                                //the line below will check if the option is an array,
                                //if so it will assume the first option is only for july
                                //and the rest are for other months
                                //used to change the first field to "last month" from "last year's"
                                <option>{Array.isArray(o) ? (currMonth == 'July' ? o[0]: o[1]) : o}</option>
                            ))}
                            </Form.Control>
                            </>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="submit-button btn btn-primary" onClick={()=>this.handleFormSubmit()}>Submit</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

function PointTable(props) {
    const { estimate, city, month, maxPoints, getPointRanges } = props;

    const pointStuff = getPointRanges(estimate, city, month, maxPoints);

    return (
        <div>
            <Table responsive striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>Points Scored…</th>
                        {pointStuff.pointsArr.map((point, i) => {
                            return (<th key={`value-${i}`}>{point}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>If Rainfall is Between…</th>
                        {pointStuff.pointsRangeArr.map((point, i) => {
                            return (<th key={`range-${i}`}>{point}</th>)
                        })}
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}
