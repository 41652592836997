/**
 * footer.jsx: A footer containing links and copyright information.
 * 
 * Author: Thomas Weiss, Benni Delgado, and CJ Larsen
 * Arizona Institute for Resilience
 */

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
export default class Footer extends React.Component {
    render(){
        return (
            <Container>
                <footer className="py-3 my-3">
                    <Row className="nav text-center justify-content-center border-top border-bottom py-2 mb-2">
                        <Col className="nav-item col-12 col-md-6 col-lg-3"><a href="/terms" className="nav-link px-2 text-muted">Terms and Conditions</a></Col>
                        <Col className="nav-item col-12 col-md-6 col-lg-3"><a href="https://air.arizona.edu/about" target="_blank" rel="noopener noreferrer" className="nav-link px-2 text-muted">About</a></Col>
                        <Col className="nav-item col-12 col-md-6 col-lg-3"><a href="https://research.arizona.edu/" target="_blank" rel="noopener noreferrer" className="nav-link px-2 text-muted">Resarch, Innovation & Impact</a></Col>
                        <Col className="nav-item col-12 col-md-6 col-lg-3"><a href="https://air.arizona.edu/contact" target="_blank" rel="noopener noreferrer" className="nav-link px-2 text-muted">Contact AIR</a></Col>
                    </Row>
                    <Row className="text-center justify-content-center py-3">
                        <Col className="text-muted">Contact&nbsp;<a href="mailto:fantasymonsoon@list.arizona.edu" className="text-muted">fantasymonsoon@list.arizona.edu</a>&nbsp;for questions or more information.</Col>
                    </Row>
                    <Row className="text-center justify-content-center mb-3">
                        <Col className="text-muted">&copy; 2022-2024 Arizona Board of Regents on behalf of the University of Arizona, Arizona Institute for Resilience</Col>
                    </Row>
                </footer>
            </Container>
        )
    }
}