/**
 * group.jsx
 */
import React from 'react';
import { Container, Row, Col, Spinner, Modal, Form } from 'react-bootstrap';
import GroupLeaderboards from './groupleaderboards';
import firebase from 'firebase/app';
import { Collapse } from './wrappers';
import AIDetails from './aidetails';

export default class GroupLeaderboardViewer extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            groupId: props.params.group,
            invalidGroup: true,
            invalidCompareGroup: props.params.compare ? true : false,
            loading: true,
            secondLoading: true,
            compareto: props.params.compare ?? null,
            pulledCompareList: false,
            compareList: [],
            compareIds: []
        }
    }
    componentDidMount(){
        this.fetchGroup(this.state.groupId, true);
        if(this.props.params.compare){
            this.fetchGroup(this.state.compareto, false);
        }
    }
    componentDidUpdate(){
        if(this.props.params.group != this.state.groupId){
            //new groupid given (likely link in menu)
            //need to requery and show new group
            this.setState({loading: true});
            this.setState({groupId: this.props.params.group})
            this.fetchGroup(this.props.params.group, true)
            
        }
        if(this.props.params.compare != this.state.compareto){
            if(this.props.params.compare == null){
                this.setState({compareto: null});
            } else {
                //new compare given
                //need to query data
                this.setState({loading: true});
                this.setState({compareto: this.props.params.compare})
                this.fetchGroup(this.props.params.compare, false);
            }
        }
    }
    async fetchGroup(groupId, primary){
        const { firestore } = this.props;

        if(primary){
            this.setState({invalidGroup: true});
        } else {
            this.setState({invalidCompareGroup: true});
        }
        await firestore.collection('groups').doc(groupId).get().then((doc) => {
            if(doc.exists){
                if(primary){
                    //primary group
                    this.setState({invalidGroup: false, [groupId]: doc.data()});
                } else {
                    //secondary group
                    this.setState({invalidCompareGroup: false, [groupId]: doc.data()})
                }
                this.fetchUsernames(groupId);
                this.fetchGroupList(this.props.user.uid);
            } else {
                this.setState({loading: false})
            }
        }).catch((e) => {
            console.error(e);
        });
    }

    async fetchGroupList(uid){
        var compareList = [];
        var compareIds = [];
        const { firestore } = this.props;
        await firestore.collection('groups').where('memberList', 'array-contains', uid).get().then((res) => {
            res.docs.forEach((doc) => {
                const data = doc.data();
                compareList.push({
                    name: data.name,
                    id: doc.id,
                    members: data.memberList.length
                });
                compareIds.push(doc.id);
            })
        }).catch((e)=>{
            console.error(e);
        });
        compareList.sort((a, b) => {return a.name.localeCompare(b.name);});
        this.setState({compareList: compareList, compareIds: compareIds, pulledCompareList: true});
    }

    async fetchUsernames(groupId) {
        const { firestore } = this.props;
        const usersRef = firestore.collection('users');
        var userlist = this.state[groupId].memberList;
        var returnList = [];
        if(userlist.length > 0){
            // list comparison is limited to 10 entries (thanks, firestore)
            // need to potentially run the where() function multiple times
            // depending on group size
            var runs = 1;
            var firestore_limit = 10; //in case this ever increases
            if(userlist.length > firestore_limit){
                runs = Math.floor(userlist.length / firestore_limit); // e.g., if 36, this should return 3
                if(userlist.length % firestore_limit > 0){
                    runs++; // add an extra run if there's a remainder
                }
            }
            for(let i = 0; i < runs; i++){
                //slice the input array based on which run we're on
                await usersRef.where(firebase.firestore.FieldPath.documentId(), 'in', userlist.slice(i * firestore_limit, (i+1) * firestore_limit)).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const user = doc.data();
                        if(user.username){
                            //only show a user if they have a username
                            returnList.push({uid: doc.id, username: user.username});
                        }
                    })
                }).catch((e) => {
                    console.error(e);
                });
            }
        }
        this.setState({[`${groupId}-users`]: returnList, loading: false });
        if(groupId == this.props.params.compare || (!this.props.params.compare)){
            this.setState({secondLoading: false});
        }
    }

    hideCompare() {
        if(this.state.compareIds.length > 2){
            // at least 3 groups
            // in all possible cases, there is at least one group available to show
            return false;
        } else if (this.state.compareIds.length <= 0){
            // no groups at all, hide form
            // negative is impossible, but grabbing just in case
            return true;
        } else {
            // either one or two groups in list
            if(this.state.compareIds.length == 1){
                if(this.state.compareIds.includes(this.state.groupId) || this.state.compareIds.includes(this.state.compareto)){
                    // one group joined, and it's already displayed
                    return true;
                }
            } else {
                // two groups
                if(this.state.compareIds.includes(this.state.groupId) && this.state.compareIds.includes(this.state.compareto)){
                    return true
                }
            }
        }
        return false;
    }

    render(){
        if(this.state.loading){
            // still loading
            return (
            <>
            <Container className="card-large mx-auto pt-4">
                <Row>
                    <Col>
                        <h2 className="h2 justify-content-center" style={{fontSize: '2.5em'}}>Group Leaderboard</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center py-4">
                        <Spinner className="d-flex justify-content-center" animation="border" variant="light" />
                    </Col>
                </Row>
            </Container>
            </>
            )
        } else if(this.state.invalidGroup || this.state.invalidCompareGroup || this.props.params.compare == this.props.params.group) {
            // error with primary groupid
            return (
            <>
                <Container className="card-large mx-auto pt-4">
                    <Row>
                        <Col>
                            <h2 className="h2 justify-content-center" style={{fontSize: '2.5em'}}>Group Leaderboard</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col  className="d-flex justify-content-center py-4">
                            <p className="d-flex p-0 m-0">{this.props.params.compare == this.props.params.group ? 'The comparison group ID must be different than the primary group ID.' : this.state.invalidGroup ? 'The group ID provided is invalid.' : 'The comparison group ID provided is invalid.'}</p>
                        </Col>
                    </Row>
                </Container>
            </>
            )
        } else {
            // primary group id is good
            return (
                <>
                <Container className="card-large p-3 pt-4 mx-auto">
                    <Row className="mb-3">
                        <Col>
                            <h2 className="h2 justify-content-center" style={{fontSize: '2.5em'}}>{this.props.params.group && this.props.params.compare ? 'Group Comparison' : this.state[this.state.groupId].name}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container>
                                <Row className="mb-0 pb-0">
                                    <Col className="col-12 col-lg-6 text-lg-left pl-lg-3 text-center mb-0 pb-0">
                                        {this.state[this.state.groupId].static || this.props.params.group && this.props.params.compare ? null :
                                        <button 
                                            className="secondary-button btn btn-secondary px-5 px-lg-3" 
                                            onClick={()=>this.props.history.push(`/groups/${this.state.groupId}`)}
                                        >{ this.state[this.state.groupId].memberList.includes(this.props.user.uid) 
                                        ? this.state[this.state.groupId].uid == this.props.user.uid || this.state[this.state.groupId].moderatorList.includes(this.props.user.uid) 
                                        ? 'Manage Group' : 'View Group' : 'Join Group' }</button> }
                                    </Col>
                                    {this.hideCompare() ? null : <Col className="col-12 col-lg-6 pr-lg-3 text-lg-right text-center mb-0 pb-0">
                                        <span className="mr-2 text-white d-block d-lg-inline-block">Compare {this.props.params.group && this.props.params.compare ? this.state[this.state.groupId].name : null} to</span>
                                        {(<Form.Group className="form-inline d-inline-block">
                                            <Form.Control 
                                                as="select" 
                                                onChange={e => {
                                                    if(e.target.value != ''){
                                                        this.props.history.push(`/groups/${this.state.groupId}/leaderboard/compare/${e.target.value}`);
                                                    }
                                                }} 
                                                id="compare"
                                                defaultValue={ this.state.compareIds.includes(this.state.compareto) ? this.state.compareto : ""}
                                            >
                                                <option value="" disabled={true}>Select one...</option>
                                                {this.state.compareList.map((group) => {
                                                    if(group.id != this.state.groupId){
                                                        return (
                                                        <option value={group.id} key={group.id}>
                                                            {group.name}
                                                        </option>
                                                    )}
                                                })}
                                            </Form.Control>
                                        </Form.Group>)
                                        }
                                    </Col> }
                                </Row>
                                <Row className="mt-0 pt-0">
                                    <Col>
                                        <hr className="border-secondary" />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.secondLoading ?
                            null : <GroupLeaderboards
                                primaryGroupName={this.state[this.state.groupId] ? this.state[this.state.groupId].name : null}
                                primaryGroupId={this.props.params.group}
                                uid={this.props.user.uid}
                                firestore={this.props.firestore}
                                memberList={this.state[this.state.groupId + '-users'] ?? []}
                                hideYear={this.state.groupId == 'ai-predictions'}
                                secondaryMemberList={this.state[this.state.compareto + '-users'] ?? null}
                                secondaryGroupName={this.state[this.state.compareto] ? this.state[this.state.compareto].name : null}
                                secondaryGroupId={this.props.params.compare}
                                history={this.props.history}
                                sendPrimaryGroupData={(e) => this.setState({primaryGroupData: e})}
                            />}
                        </Col>
                    </Row>
                </Container>
                { this.state.groupId == 'ai-predictions' ? (<AIDetails data={this.state.primaryGroupData ?? []} />) : null }
                </>
            )
        }
    }
}