import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
    MenuToggle, DashboardIcon, DigestIcon, EstimateIcon, HeadphoneIcon,
    HomeIcon, LeaderboardsIcon, ProfileIcon, ScoringIcon, SurveyIcon,
    ExternalLinkIcon, GroupIcon, TermsIcon, NewsIcon, OutlooksIcon,
    ResourcesIcon
} from './svgComponents';

export default class MenuSidebar extends React.Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const month = today.getMonth() + 1 // jan is 0

        let currMonth = "";
        if (month > 5 && month < 9) { // next month is guessable month
            today.setMonth(month + 0);
            currMonth = today.toLocaleString('en-US', { month: 'long' });
        }

        this.state = {
            open: false,
            currMonth: currMonth
        }
    }

    closeMenu = () => {
        this.setState({ open: false })
    }

    render() {
        const sidebar = {
            open: (height = 1000) => ({
                clipPath: `circle(${height * 2.5 + 200}px at 40px 40px)`,
                transition: {
                    type: "spring",
                    stiffness: 20,
                    restDelta: 2
                }
            }),
            closed: {
                clipPath: "circle(30px at 40px 40px)",
                transition: {
                    type: "spring",
                    stiffness: 400,
                    damping: 40
                }
            }
        };
        const { open, currMonth } = this.state;
        return (
            <motion.nav
                initial={false}
                animate={open ? "open" : "closed"}
                className="menu-container"
            >
                <motion.div className="menu-background" variants={sidebar} />
                {open && <Navigation currMonth={currMonth} changeMenu={this.props.changeMenu} closeMenu={this.closeMenu} setCity={this.props.setCity} signOut={this.props.signOut} user={this.props.user}/>}
                <MenuToggle toggle={() => this.setState({ open: !open })} />
            </motion.nav>
        )
    }
}

function Navigation(props) {
    //all menus
    const menuItems = [
        { name: "Dashboard", key: "home", logo: DashboardIcon(), href: "/dashboard" },
        { name: `Make ${props.currMonth} Forecasts`, key: "estimate", logo: EstimateIcon(), href: "/estimate" },
        { name: "Leaderboard", key: "leaderboards", logo: LeaderboardsIcon(), href: "/leaderboard" },
        { name: "Group Leaderboards", key: "grouplist", logo: LeaderboardsIcon(), href: "/groups/leaderboards"},
        { name: "AI Model Leaderboard", key: "groupleaderboard", logo: LeaderboardsIcon(), href: "/groups/ai-predictions/leaderboard"},
        { name: "Your Groups", key: "group", logo: GroupIcon(), href: "/groups" },
        { name: "Game-wide Forecasts", key: "digest", logo: DigestIcon(), href: "/digest" },
        { name: "NOAA Outlooks", key: "outlooks", logo: OutlooksIcon(), href: "/outlooks" },
        { name: "Monsoon Resources", key: "resources", logo: ResourcesIcon(), href: "/resources" },
        { name: "Profile", key: "profile", logo: ProfileIcon(), href: "/profile" },
        { name: "SW Climate Podcast", key: "podcast", logo: HeadphoneIcon(), href: "https://climas.arizona.edu/podcasts", external: true },
        { name: "Game Updates", key: "updates", logo: NewsIcon(), href: "/updates" },
        { name: "Scoring Description", key: "scoring", logo: ScoringIcon(), href: "/scoring" },
        { name: "Terms and Conditions", key: "terms", logo: TermsIcon(), href: "/terms"}
    ]
    //animation variants
    const ulVariants = {
        open: {
            transition: { staggerChildren: 0.02, delayChildren: 0.05 }
        },
        closed: {
            transition: { staggerDirection: -1, staggerChildren: 0.05, delayChildren: 0.2 }
        }
    };
    const liVariants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
            }
        },
        closed: {
            y: 0,
            opacity: 0,
            transition: {
                y: { stiffness: 1000 }
            }
        }
    };

    //destructure changeMenu function
    const { changeMenu, closeMenu, setCity, signOut, user } = props;
    return (
        <React.Fragment>
        <motion.ul variants={ulVariants}>
            {menuItems.map((item) => (
                <motion.li
                    variants={liVariants}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.97 }}
                    key={item.key}
                >
                    <h4 className="menu-text">
                        <Link 
                            onClick={ () => item.external ? null : closeMenu() } 
                            to={item.external ? { pathname: item.href } : item.href} 
                            target={item.external ? '_blank' : '_self'} 
                            rel={item.external ? 'noopener noreferrer' : ''} 
                            style={{ color: '#000000' }}
                        >
                            {item.logo}<span className="ml-3 mr-2">{item.name}</span>{item.external ? ExternalLinkIcon() : null }
                        </Link>
                    </h4>
                </motion.li>
            ))}
            <motion.li
                variants={liVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.97 }}
                key={'logout'}
            >
                <a
                  onClick={ () => signOut() }
                  style={{ color: '#000000' }}>
                  <h4 className="menu-text">
                    Sign Out
                  </h4>
                </a>
            </motion.li>
        </motion.ul>
        </React.Fragment>
    )
}


