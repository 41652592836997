/**
 * scoring.jsx: simple wrapper for the scoring description to make it available
 *   to non-authenticated users
 * Author: Benni Delgado and CJ Larsen
 * Arizona Institute for Resilience
 */

import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/footer';

import ScoringDesc from "../components/scoringDesc";

export default function Home(props) {
    const user = props.user;
    return (
        <>
            <main className="home">
                <NavBar user={user} signIn={props.signIn} history={props.history} />
                <div style={{ marginTop: '7em' }}>
                    <ScoringDesc/>
                </div>
            </main>
            <Footer />
        </>
    )
}


