/**
 * protected.jsx: Represents the auth required page of the monsoon-game, handles routing
 *              to the other private user pages. A user is only allowed access
 *              here if they have already authorized with firebase
 * Author: Benni Delgado & CJ Larsen
 * Arizona Institute for Resilience
 * 
 * Modified by Thomas Weiss
 */
import React from 'react';
import MenuSidebar from '../components/menuSidebar';
import { motion } from 'framer-motion';
//import the diffrent pages
import Dashboard from '../components/dashboard';
import ProfileSettings from '../components/profileSettings';
import Leaderboards from '../components/leaderboards';
import Estimate from '../components/estimate';
import Survey from '../components/surveyQuestions';
import Scoring from '../components/scoringDesc';
import Digest from '../components/monthlyDigest';
import GameUpdates from '../components/gameUpdates';
import Group from '../components/group';
import Resources from '../components/resources';
import { ToastContainer, toast } from 'react-toastify';
import Footer from '../components/footer';
import { Modal } from 'react-bootstrap';
import Terms from '../components/terms';
import Outlooks from '../components/outlooks';
import GroupLeaderboardViewer from '../components/groupleaderboardviewer';
import GroupList from '../components/grouplist';

const currentMenu = {
    "dashboard": Dashboard,
    "estimate": Estimate,
    "leaderboards": Leaderboards,
    "surveys": Survey,
    "profile": ProfileSettings,
    "scoring": Scoring,
    "digest": Digest,
    "updates": GameUpdates,
    "resources": Resources,
    "group": Group, 
    "outlooks": Outlooks,
    "groupleaderboard": GroupLeaderboardViewer,
    "grouplist": GroupList
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const cities = ['albuquerque', 'el_paso', 'tucson', 'flagstaff', 'phoenix'];

export default class ProtectedPage extends React.Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const dd = today.getDate();
        // jan is 0 so +1 to make it 1. We always want the next month for forecasting.
        const mm = today.getMonth() + 1;
        const currYear = String(today.getUTCFullYear());

        //check if city param is passed through, if so, make sure it's valid
        if(this.props.params.city && !cities.includes(this.props.params.city)){
            //not valid, we need to go to the default estimate page
            this.props.history.push('/estimate');
        }

        // if current month is month before a guessing month, set it to guessing month
        let currMonth = mm > 6 && mm < 10 ? mm : 6;
        // if we're in september, set display september stats by setting
        // currMonth to August. mm is used to determine if forecasts are allowed
        // to be made so this works
        if (currMonth == 9) {
            currMonth--;
        }

        //default menu will be the dashboard
        this.state = {
            menu: "dashboard",
            menuName: "Dashboard",
            info: {
                currMonth: months[currMonth],
                currYear: currYear,
                closed: (mm < 6 || mm > 8), // closed outside of months Jun, jul, aug (forecasts for jul, aug, sep)
                currCity: !this.props.params.city ? 'tucson' : cities.includes(this.props.params.city) ? this.props.params.city : 'tucson'
            },
            menuNames: {
                "dashboard": "Dashboard",
                "estimate": `Make ${months[currMonth]} Forecasts`,
                "leaderboards": "Leaderboard",
                "surveys": "Surveys",
                "profile": "Profile",
                "scoring": "Scoring Description",
                "digest": "Game-wide Forecasts",
                "updates": "Game Updates",
                "resources": "Monsoon Resources",
                "outlooks": "NOAA Outlooks", 
                "group": "Fantasy Groups",
                "groupleaderboard": "",
                "grouplist": "Group Leaderboards"
            },
            showTerms: false,
            // between seasons modal (show in october as well so people can check leaderboards w/ no popup)
            showPreSeasonModal: (mm < 6 || mm > 10)
        }

        //check if user has agreed to this year's terms
        const userRef = props.firestore.collection('users');
        userRef.doc(props.user.uid).get().then(async (doc) => {
            const data = doc.data();
            if(!doc.exists || !data['agreed' + currYear]){
                this.setState({showTerms: true})
            }
        }).catch((e) => {
            console.error(e);
        });
    }

    agreeToTerms = async () => {
        const {firestore} = this.props;
        const {uid} = this.props.user;
        const userRef = firestore.collection('users');
        userRef.doc(uid).set({
            ['agreed' + this.state.info.currYear]: true
        }, {merge: true}).then(() => {
            this.throwToast('You have agreed to the terms of use.', 'success');
            this.setState({showTerms: false});
        }).catch((e)=>{
            console.error(e);
        });
    }
    
    /**
     *  throws a toast given a msg and a toast type
     * @param {String} msg 
     * @param {String} type 
     */
    throwToast = (msg, type) => {
        switch (type) {
            case "error":
                toast.error(msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                break;
            case "success":
                toast.success(msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                break;
            default:
                toast.info(msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                break;
        }
    }

    /**
     * Called upon by MenuSidebar child, sets the state for the current
     * page forcing a re-render.
     * @param {String} menu -- The name of the current menu
     */
    changeMenu = ({ key, name }) => {
        this.setState({ menu: key, menuName: name });
    }

    setCity = (city) => {
        this.setState({ info: { ...this.state.info, currCity: city } })
    }

    render() {
        const { firestore, user, time, signOut, history, params } = this.props;
        const { menuNames, menu, info, showTerms, showPreSeasonModal } = this.state;
        const CurrentMenu = currentMenu[this.props.component];
        return (
            <>
            { this.state.showTerms == false && this.state.showPreSeasonModal == false ? 
                (<>
                <MenuSidebar changeMenu={this.changeMenu} setCity={this.setCity} user={user} signOut={signOut}/>
                <motion.h1 className="d-flex justify-content-center mb-2 mt-5"
                    animate={{ opacity: [0, 1] }}
                >{menuNames[this.props.component]}</motion.h1>
                <CurrentMenu
                    user={user}
                    time={time}
                    info={info}
                    firestore={firestore}
                    toast={this.throwToast}
                    changeMenu={this.changeMenu}
                    setCity={this.setCity}
                    history={history}
                    params={params}
                    currYear={info.currYear}
                />
                <ToastContainer />
                <Footer />
                </>) : null }

                {/* Before Season Modal */}
                <Modal centered={true} show={this.state.showPreSeasonModal && !this.state.showTerms} handleClose={()=>this.setState({showPreSeasonModal: false})} backdrop="static" animation={false} scrollable={true}>
                    <Modal.Header>
                        <Modal.Title className="text-white">
                            <h3 className="h4">Game not currently in session</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Monsoon Fantasy only runs from June through September. While the website is still live, game features and website functionality may be adjusted or updated between seasons.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="secondary-button btn btn-secondary" onClick={()=>this.setState({showPreSeasonModal: false})}>Close</button>
                    </Modal.Footer>
                </Modal>
                {/* Terms Modal */}
                <Modal centered={true} show={this.state.showTerms} handleClose={()=>this.setState({showTerms: false})} backdrop="static" animation={false} scrollable={true}>
                    <Modal.Header>
                        <Modal.Title className="text-white">
                            <h3 className="h4">Terms of Use</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Terms />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="secondary-button btn btn-secondary" onClick={()=>{this.props.history.push('/home')}}>Back to Home</button>
                        <button className="submit-button btn btn-primary" onClick={()=>this.agreeToTerms()}>I Agree</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
