/**
 * 
 * Author: CJ Larsen
 * Arizona Institute for Resilience
 */
import React from 'react';
import { motion } from 'framer-motion';
import { Row, Container, Col, Table } from 'react-bootstrap';
import ReactEmbedGist from 'react-embed-gist';

import EstimateImage from '../media/estimate-desc.png';
import CurveImage from '../media/curve-desc.png';

export default class Survey extends React.Component {
    constructor(props) {
        super(props);
        document.querySelector("body").scrollTo(0, 0);
    }

    render() {
        return (
            <motion.div animate={{ opacity: [0, 1] }} className="card-large">
                <Container fluid>
                    <Row className="d-flex align-content-left" style={{ paddingTop: "2%" }}>
                        <Col className="d-flex justify-content-left">
                            <h4>Overview</h4>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-left" style={{ padding: "2%" }}>
                        <p>Points are scored each month and for each city, with the winner determined by the total points accumulated over the three months and five cities. </p>
                        <p>For each month and each city, points are calculated by considering the riskiness of the forecast and its accuracy. The risk of the forecast depends on how likely the forecasted value is to actually occur based on the historical record. Less likely estimates are those that occur further from the average. Riskier forecasts have higher possible points. They are possible points because the scoring also depends on the accuracy of the forecast. The more accurate the forecast is, the more points the player receives.</p>
                        <p>Let’s look at an example for Tucson (at the Tucson International Airport).</p>
                        <p>A histogram of July rainfall totals for Tucson between 1899-2020 (121 years) is shown below in the figure’s blue bars. Each vertical bar represents the number of times Tucson received rainfall in July within a quarter inch range. For example, in 9 of the 121 years, Tucson received between 4 and 5 inches.  </p>
                    </Row>
                    <Row className="d-flex justify-content-center" style={{ paddingBottom: "2%" }}>
                        <img
                            className="desc-img"
                            src={EstimateImage}
                            alt="Estimate"
                        />
                    </Row>
                    <Row className="d-flex align-content-left">
                        <Col className="d-flex justify-content-left">
                            <h4>Maximum Potential Points for your Forecast</h4>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-left" style={{ padding: "2%" }}>
                        <Col className="d-flex justify-content-left">
                            <p>Because Tucson experienced only a few months with rainfall between 6 and 7 inches, a player who forecasts 6 inches has the potential to earn a higher number of points than someone who estimates a forecast closer to the average rainfall (about 2 inches) for the month. In other words, lower probable outcomes generate greater benefits—higher risk equals higher reward. </p>
                        </Col>
                    </Row>
                    <Row className="d-flex align-content-left">
                        <Col className="d-flex justify-content-left">
                            <h4>Total Points Based on Accuracy</h4>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-left" style={{ padding: "2%" }}>
                        <Col >
                            <p>Riskiness determines the maximum points that can be obtained. The accuracy determines how many of those points are actually scored. Accuracy is determined by comparing the forecast to the actual rainfall. The more accurate the forecast, the more of the maximum points awarded. </p>
                            <p>Because it is unlikely that any forecast is perfect, accuracy is determined in four tiers. Each tier represents a range. The range is smallest for the tier that awards the most points. In other words, to score 100% of the maximum points, the actual rainfall has to be close to the forecast.</p>
                            <p>The range is calculated using the error in percentile rank between the estimate and actual rainfall. For example, if a forecast falls in the 60th percentile and the actual rainfall is in the 67th percentile, the error would be 7%. For the purposes of scoring, estimates and actual rainfall that exceed the 99th percentile are considered to have percentiles equal to their value divided by the historical maximum, even though percentiles greater than 99 don't exist. This is to allow for forecasting above the historic maximum. The four tiers range in increments of 5% from 0 to 20, as shown in the table.</p>
                            <Table striped bordered hover variant="dark">
                                <thead>
                                    <tr>
                                        <th>Accuracy Range</th>
                                        <th>% of Maximum Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>+/- 5 percentile </td>
                                        <td>100</td>
                                    </tr>
                                    <tr>
                                        <td>+/- 10 percentile </td>
                                        <td>75</td>
                                    </tr>
                                    <tr>
                                        <td>+/- 15 percentile</td>
                                        <td>50</td>
                                    </tr>
                                    <tr>
                                        <td>+/- 20 percentile </td>
                                        <td>25</td>
                                    </tr>
                                    <tr>
                                        <td>{'>'} 20 percentile </td>
                                        <td>0</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p>Again, let’s look at Tucson in July as an example. To score 100% of the maximum points for a forecast of 5 inches(~92nd percentile), the rainfall would need to fall between ~4.11(~87th percentile) and ~5.51(~97th percentile) inches. A score of 75% of the maximum points occurs if the rainfall is between ~3.46"(~82nd percentile) and ~4.11" or 5.51 and 6.96(> 99th percentile). If the bounds of a range fall above the 99th percentile, we calculate the range by multiplying the greatest recorded value by the proposed percentile. In our example above, the greatest recorded rainfall for Tucson is 6.8 inches. Since the upper bound of our 75% range would have fallen at ~102.4th percentile we multiply the value by 1.024 to get 6.96.</p>
                            <p>The table of examples from Tucson below show how riskiness and accuracy can effect scoring.</p>
                            <Table responsive striped bordered hover variant="dark">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Forecast Estimate (inches)</th>
                                        <th>Possible Maximum Points</th>
                                        <th>Actual Rainfall (inches)</th>
                                        <th>Total Points Scored</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Risky and accurate forecast</td>
                                        <td>0.5</td>
                                        <td>7.38</td>
                                        <td>0.5</td>
                                        <td>7.38</td>
                                    </tr>
                                    <tr>
                                        <td>Risky but inaccurate forecast</td>
                                        <td>0.5</td>
                                        <td>7.38</td>
                                        <td>2.4</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Less risky and accurate forecast</td>
                                        <td>2</td>
                                        <td>4.12</td>
                                        <td>2.4</td>
                                        <td>3.09</td>
                                    </tr>
                                    <tr>
                                        <td>Less risky but inaccurate forecast</td>
                                        <td>2</td>
                                        <td>4.12</td>
                                        <td>2.65</td>
                                        <td>1.03</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p>For those interested in how the maximum points are derived mathematically, please read on ☺.</p>
                        </Col>
                    </Row>
                    <Row className="d-flex align-content-left">
                        <Col className="d-flex justify-content-left">
                            <h4>Here’s How We Calculate the Maximum Points</h4>
                        </Col>
                    </Row>
                    <Col className="d-flex justify-content-left">
                        <Row className="d-flex justify-content-left" style={{ padding: "2%" }}>
                            {/* i do not know why this works, it should not work, but it does */}
                            <p>First, the monthly total rainfall distributions are not normally distributed. They have a positive skew which means the mean value is greater than the median value. We therefore used an equation to account for this skew.
                                <br /><br />We used a log-normal formula to determine the maximum points in a positively skewed distribution as follows.
                                <br /><br />Max points = -4 * exp(-((log(x / m))^2) / (2 * (s / 2)^2)) + 8
                                <br /><br />The variables in this formula are the following:
                                <br /><br />- “x” is the users predicted rainfall in the month this year.
                                <br /><br />- “m” is the average rainfall for the month calculated from the historical record, and
                                <br /><br />- “s” is the standard deviation of the historical data.
                                <br /><br />We can graph this equation with respect to the user's predicted rainfall on the x-axis and the possible maximum points scored on the y-axis. We show this for Tucson’s July rainfall where the mean is 2.39 inches and the standard deviation is 1.03 inches.</p>
                            <img
                                className="desc-img"
                                src={CurveImage}
                                alt="Curve"
                            />
                            <p><br />This graph shows that as the forecasted value is further away from the mean, the maximum possible points are higher. The graph also shows that the lowest maximum points are for values that are closer to the mean. </p>
                        </Row>
                    </Col>
                    <Row className="d-flex align-content-left">
                        <Col className="d-flex justify-content-left">
                            <h4>Here’s How We Calculate the Points Scored Each Month</h4>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-left" style={{ padding: "2%" }}>
                        <p>We calculate the points by determining how close the forecast is to the actual rainfall values and allocating a fraction of the maximum points based on this accuracy. Accuracy is determined by percentile difference between forecasts and actual rainfall. There are four tiers as described in the table above. </p>
                    </Row>
                    <Row className="d-flex align-content-left">
                        <Col className="d-flex justify-content-left">
                            <h4>Javascript Code</h4>
                        </Col>
                    </Row>
                    <Row xs={1} className="d-flex justify-content-left" style={{ padding: "2%" }}>
                        <p>The following code is how we calculate points. It utilizes the <a href="https://d3js.org/">d3</a> javascript library.</p>
                        <ReactEmbedGist gist="lboeman/48692785cfd4f56c7fc571173a8060fa" />
                    </Row>
                </Container>
            </motion.div >
        )
    }
}
