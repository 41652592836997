/**
 * group.jsx - This serves as the group management page wherein a user can create, join, leave, and manage groups.
 * Routing allows for a group to be shown via URL (or to prompt to join if applicable). Each group will
 * also link to their respective leaderboard pages.
 * 
 * Arizona Institute for Resilience
 * Author: Thomas Weiss
 */

import React from 'react';
import { Container, Row, Col, Spinner, Modal } from 'react-bootstrap';
import GroupLeaderboards from './groupleaderboards';
import firebase from 'firebase/app';
import randomWords from '../data/randomwords.json';
import { Collapse } from './wrappers';

export default class Group extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            newGroupName: '', //input for create form
            joinCode: '', //input for join form
            joinGroupName: null,
            showJoinModal: false,
            showAlreadyJoinedModal: false,
            hideCreate: false, //boolean to hide create form
            cancelRequest: null, //groupid to potentially cancel request for
            deleteGroup: null, //groupid to potentially delete
            leaveGroup: null, //groupid to potentially leave
            groupToShow: null, //groupid being viewed
            groupToShowData: null, //data for shown group
            joinedGroups: [], //users joined groups
            pendingGroups: [], //users pending groups
            createdGroups: [], //users created groups
            userToHandle: null,
            userAction: null,
            showConfirm: false,
            max_groups: 5, //maximum number of groups to create
        }

    }

    /**
     * Initiates confirmation modal for cancelling join request.
     * @param {String} group - Group ID to cancel request for.
     */
    handleCancel = (group) => {
        this.setState({cancelRequest: group})
    }

    /**
     * Initiates confirmation modal for deleting group.
     * @param {String} group - Group ID of group to delete.
     */
    handleDelete = (group) => {
        this.setState({deleteGroup: group})
    }

    /**
     * Initiates confirmation modal for leaving group.
     * @param {String} group - Group ID of group to leave.
     */
    handleLeave = (group) => {
        this.setState({leaveGroup: group})
    }

    /**
     * Opens modal with group details of indicated group.
     * @param {String} group - Group ID of group to view.
     */
    handleView = (group) => {
        this.setState({groupToShow: group});
        //add this group id to the address bar for easy sharing
        //and maintaining history
        this.props.history.push('/groups/' + group);
    }

    /**
     * Resets variables that are linked to open modals, closing them.
     */
    closeModal = (keepGroupToShow) => {
        this.setState({ 
            cancelRequest: null, 
            deleteGroup: null, 
            leaveGroup: null, 
            groupToShow: keepGroupToShow ? this.state.groupToShow : null, 
            groupToShowData: keepGroupToShow ? this.state.groupToShowData : null,
            showJoinModal: false,
            joinGroupName: null,
            showAlreadyJoinedModal: false,
            showConfirm: false,
            userAction: null,
            userToHandle: null
        });
        // modal is closed, let's revert back to the groups url
        if(!keepGroupToShow){
            this.props.history.push('/groups');
        }
    }

    /**
     * On mounting of this main exported component.
     */
    componentDidMount() {
        // get the users groups
        this.getGroups();
        this.checkHasUsername().then((hasUsername) => {
            if(!hasUsername){
                this.props.history.push('/profile');
            }
        });
    }

    /**
     * On update of this current component.
     */
    async componentDidUpdate(){
        // check if the groupToShow is set but no data has been pulled
        if(this.state.groupToShow != null && this.state.groupToShowData == null){
            // get the current group data
            this.getCurrentGroupData(this.state.groupToShow);
        }
        if(this.state.groupToShow != null && !this.props.params.group){
            // we have a group to show state, but no actual prop
            // let's remove the groupToShow state
            this.setState({groupToShow: null});
        }
        if(this.props.params.group && this.props.params.group != this.state.groupToShow){
            // there is a group param, but the state is different, we need to 
            // requery data and set the groupToShow to be the proper id.
            this.setState({groupToShow: this.props.params.group})
            this.getCurrentGroupData(this.props.params.group);
        }
    }

    /**
     * Pulls a list of groups that contain the current user ID in some capacity.
     */
    getGroups = async () => {
        // generate a random name to use in the create group field
        this.generateRandomName();
        
        const {firestore} = this.props;
        const {uid} = this.props.user;
        const groupsRef = firestore.collection('groups');

        var alreadyRequested = false;

        // get groups where user has requested to join
        await groupsRef.where('requestList', 'array-contains', uid).get().then(
            (querySnapshot) => {
              this.setState({ pendingGroups: querySnapshot.docs}); 
              // check if group query parameter is given
              if(this.props.params.group != null){
                for(let i = 0; i < querySnapshot.docs.length; i++){
                    if(querySnapshot.docs[i].id === this.props.params.group){
                        //user has already requested to join this group
                        alreadyRequested = true;
                        this.setState({ showAlreadyJoinedModal: true, joinGroupName: querySnapshot.docs[i].data().name });
                    }
                }
              }
            }
        );

        // get groups where user is a member (will also include moderated groups)
        await groupsRef.where('memberList', 'array-contains', uid).get().then(
            (querySnapshot) => {
              this.setState({ joinedGroups: querySnapshot.docs});
              
              // check if group query parameter is given and user hasn't already
              // requested to join
              if(this.props.params.group != null && !alreadyRequested){
                // parameter exists, now we need to see if the member is in it
                var inGroup = false;
                for(let i = 0; i < querySnapshot.docs.length; i++){
                    if(querySnapshot.docs[i].id === this.props.params.group){
                        // group id matches given id, meaning user is in the group
                        this.setState({ groupToShow: this.props.params.group })
                        inGroup = true;
                    }
                }
                if(!inGroup){
                    // no matches were found, so we'll consider throwing this parameter
                    // into the join code form, but we should check if it's valid
                    groupsRef.doc(this.props.params.group).get().then(async (doc) => {
                        if(doc.exists){
                            // valid group code, let's pre-fill the form and show a modal
                            const data = await doc.data();
                            if(!data['static']){
                                //group isn't static, we can show the join group option
                                this.setState({ joinCode: doc.id, showJoinModal: true, joinGroupName: data.name });
                            } else {
                                this.props.history.push('/groups');
                            }
                        } else {
                            this.props.history.push('/groups');
                        }
                    }).catch((e) => {
                        console.error(e);
                    });
                }
              }
            }
        );

        // get created groups, mostly for checking on group creation limit
        await groupsRef.where('uid', '==', uid).get().then(
            (querySnapshot) => {
                this.setState({ createdGroups: querySnapshot.docs});
                // check if user has too many groups
                if(querySnapshot.docs.length >= this.state.max_groups){
                    this.setState({hideCreate: true});
                } else {
                    this.setState({hideCreate: false});
                }
            }
        );
    }

    /**
     * Gets a group's data from the already pulled list.
     * @param {String} id - Group ID to pull data for.
     * @returns 
     */
    getGroupData = (id) => {
        // we only need to search join and requested, as a creator/moderator
        // should always be joined
        for(let i = 0; i < this.state.joinedGroups.length; i++){
            if(this.state.joinedGroups[i] && this.state.joinedGroups[i].id === id){
                return this.state.joinedGroups[i].data();
            }
        }

        for(let i = 0; i < this.state.pendingGroups.length; i++){
            if(this.state.pendingGroups[i].id === id){
                return this.state.pendingGroups[i].data();
            }
        }
    }

    /**
     * Re-queries Firestore for group data, rather than the already pulled information.
     * Mostly to check if the user's permissions have changed.
     * @param {String} id - Group ID to pull data for.
     * @returns 
     */
    getCurrentGroupData = async (id) => {
        const groupRef = this.props.firestore.collection('groups');
        
        groupRef.doc(id).get().then(async (res) => {
            const groupData = await res.data();
            if(res.exists && groupData['memberList'] && groupData['memberList'].includes(this.props.user.uid)){
                this.getGroupInformation(groupData).then(async (res) => {
                    const groupInformation = await res;
                    this.setState({groupToShowData: groupInformation});
                }).catch((e) => {
                    console.error(e);
                });
            }
        }).catch((e) => {
            console.error(e);
        });
    }

    /**
     * Generate a random name to use for group creation.
     */
    generateRandomName = () => {
        const weatherNoun = randomWords.nouns[Math.floor(Math.random() * randomWords.nouns.length)];
        const weatherAdjective = randomWords.adjectives[Math.floor(Math.random() * randomWords.adjectives.length)];
        const weatherAdverb = randomWords.adverbs[Math.floor(Math.random() * randomWords.adverbs.length)];

        this.setState({newGroupName: `${weatherAdverb} ${weatherAdjective} ${weatherNoun}`});
    }

    /**
     * Submits a new group to Firestore. Upon completion, reloads the page.
     */
    submitNewGroup = async () => {
        if(this.state.newGroupName === null || this.state.newGroupName.trim() === ''){
            // this will no longer display due to randomly generated names, but keeping
            // in case we ever add custom names in the future
            this.props.toast('Please enter a group name!', 'error');
            return;
        }
        if(this.state.createdGroups.length >= this.state.max_groups){
            this.props.toast('You have already reached the maximum number of groups!', 'error');
            return null;
        }
        if(this.state.newGroupName.length > 128){
            // same as first conditional
            this.props.toast('The group name you entered is too long!', 'error');
            return null;
        }

        const {firestore} = this.props;
        const {uid} = this.props.user;
        const groupsRef = firestore.collection('groups');

        try {
            var taken = true;
            await groupsRef.where('name', '==', this.state.newGroupName).get().then((ref) => {
                if(!ref.empty){
                    taken = true;
                    this.props.toast('Group name already taken!', 'error');
                } else {
                    taken = false;
                }
            });
            if(!taken){
                var groupInfo = {
                    uid: uid,
                    name: this.state.newGroupName.trim(),
                    memberList: [uid],
                    moderatorList: [uid],
                    requestList: [],
                    created: firebase.firestore.FieldValue.serverTimestamp()
                }
                
                this.setState({hideCreate: true});
                groupsRef.add(groupInfo).then(() => {
                    this.closeModal(false);
                    this.getGroups();
                    this.props.toast('Group successfully created.', 'success');
                });
            }
        } catch(e) {
            console.error(e);
        }
    }

    /**
     * Cancels a join request based on the `cancelRequest` state.
     */
    cancelJoinRequest = async () => {
        if(this.state.cancelRequest == null){
            this.props.toast('An error has occurred.','error');
            return;
        }
        const {firestore} = this.props;
        const {uid} = this.props.user;
        try {
            const groupRef = firestore.collection('groups').doc(this.state.cancelRequest);
            groupRef.update({
                requestList: firebase.firestore.FieldValue.arrayRemove(uid)
            }).then(() => {
                this.closeModal(false);
                this.getGroups();
                this.props.toast('Join request cancelled.', 'error');
            })
        } catch(e){
            console.error(e);
        }
        
    }

    /**
     * Deletes a group based on the `deleteGroup` state.
     */
    deleteCreatedGroup = async () => {
        if(this.state.deleteGroup == null){
            this.props.toast('An error has occurred.','error');
            return;
        }
        const {firestore} = this.props;
        try {
            const groupRef = firestore.collection('groups').doc(this.state.deleteGroup);
            groupRef.delete().then(() => {
                this.closeModal(false);
                this.getGroups();
                this.props.toast('Group deleted.', 'error');
            })
        } catch(e){
            console.error(e);
        }
    }

    /**
     * Leaves a group based on the `leaveGroup` state.
     */
    leaveJoinedGroup = async () => {
        if(this.state.leaveGroup == null){
            this.props.toast('An error has occurred.','error');
            return;
        }
        const {firestore} = this.props;
        const {uid} = this.props.user;
        try {
            const groupRef = firestore.collection('groups').doc(this.state.leaveGroup);
            groupRef.update({
                memberList: firebase.firestore.FieldValue.arrayRemove(uid),
                moderatorList: firebase.firestore.FieldValue.arrayRemove(uid)
            }).then(() => {
                this.getGroups();
                this.closeModal(false);
                this.props.toast('Removed self from group.', 'error');
            })
        } catch(e){
            console.error(e);
        }
    }

    /**
     * Checks if the currently logged in user has a username.
     * @returns boolean
     */
    checkHasUsername = async () => {
        const {uid} = this.props.user;
        const {firestore} = this.props;
        const userRef = firestore.collection('users').doc(uid);
        var retVal = false;
        await userRef.get().then((doc) => {
            if(doc.exists){
                const user = doc.data();
                if(user.username && user.username != ""){
                    //username exists and is not blank
                    retVal = true;
                } else {
                    retVal = false;
                }
            } else {
                retVal = false;
            }
        }).catch((error) => {
            console.error(error);
        });
        return retVal;
    }

    /**
     * Submits a request to join a group by adding the user to the `requestList`.
     */
    submitJoinRequest = async () => {
        if(this.state.joinCode === null || this.state.joinCode.trim() === ''){
            this.props.toast('Please enter an invite code!', 'error');
            return;
        }

        const {firestore} = this.props;
        const {uid} = this.props.user;
        const groupsRef = firestore.collection('groups');

        //trim the beginning of the url in case someone pasted that instead of just a group code
        var groupId = this.state.joinCode.trim().split('/');
        groupId = groupId[groupId.length-1];
        this.setState({joinCode: groupId}); //this isn't really necessary, but it cleans up the joinGroup field

        try {
            const groupRef = await groupsRef.doc(groupId).get();
            const groupData = groupRef.data();
            if(groupRef.exists && !groupData['requestList'].includes(uid)) {
                if(groupData['static']){
                    //cannot join a static group (ai-predictions for example)
                    this.props.toast('You cannot join this group!', 'error');
                    return;
                }
                //group exists and the request list doesn't include uid
                //check if user already in memberlist 
                if(groupData['memberList'].includes(uid)){
                    this.props.toast('You are already in this group!', 'error');
                    return;
                }
                groupsRef.doc(groupId).update({
                    requestList: firebase.firestore.FieldValue.arrayUnion(uid)
                }).then(() => {
                    this.props.history.push(`/groups/${groupId}`);
                    this.getGroups();
                    this.closeModal(false);
                    this.setState({ joinCode: groupId, showAlreadyJoinedModal: true, joinGroupName: groupData['name']});
                    this.props.toast('Join request submitted.', 'success');
                });
            } else if(groupRef.data()['requestList'].includes(uid)){
                this.props.toast('You have already requested to join this group!', 'error');
                return;
            } else {
                this.props.toast('The invite code is invalid!', 'error');
                return;
            }
        } catch {
            this.props.toast('The invite code is invalid!', 'error');
            return;
        }
    }

    /**
     * Uses groupData from `getGroupData` or `getCurrentGroupData` to get usernames.
     * @param {Object} groupData 
     * @returns Object with member and request lists and accompanying usernames.
     */
    getGroupInformation = async (groupData) => {
        //get requested users only if current user is elevated
        if(groupData.uid === this.props.user.uid || groupData.moderatorList.includes(this.props.user.uid)){
            var requestList = await this.getUserData(groupData.requestList);
            //alphabetize request list
            requestList.sort((a, b) => {
                return a.username.localeCompare(b.username);
            })
        }
        //get joined users
        var memberList = await this.getUserData(groupData.memberList);

        //alphabetize member list
        memberList.sort((a, b) => {
            return a.username.localeCompare(b.username);
        });
        //
        return {
            requestList: requestList ?? [],
            memberList: memberList ?? [],
            data: groupData
        }
    }

    /**
     * Gets a list of users' information (e.g., username) from a list of `uid`s.
     * @param {Array} userlist
     * @returns List of objects with user data.
     */
    getUserData = async (userlist) => {
        var returnList = [];
        const usersRef = this.props.firestore.collection('users');
        if(userlist.length > 0){
            // list comparison is limited to 10 entries (thanks, firestore)
            // need to potentially run the where() function multiple times
            // depending on group size
            var runs = 1;
            var firestore_limit = 10; //in case this ever increases
            if(userlist.length > firestore_limit){
                runs = Math.floor(userlist.length / firestore_limit); // e.g., if 36, this should return 3
                if(userlist.length % firestore_limit > 0){
                    runs++; // add an extra run if there's a remainder
                }
            }
            for(let i = 0; i < runs; i++){
                //slice the input array based on which run we're on
                await usersRef.where(firebase.firestore.FieldPath.documentId(), 'in', userlist.slice(i * firestore_limit, (i+1) * firestore_limit)).get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const user = doc.data();
                        if(user.username){
                            //only show a user if they have a username
                            returnList.push({uid: doc.id, username: user.username});
                        }
                    })
                }).catch((e) => {
                    console.error(e);
                });
            }
        }
        return returnList;
    }
    
    /**
     * Removes a user from the `requestList`.
     * @param {String} uid 
     * @returns 
     */
    denyUser = async (uid) => {
        const loggedInUser = this.props.user.uid;
        // we need to get the current data for this group, to ensure user still has proper permissions
        const groupData = this.state.groupToShowData.data;
        if(groupData.moderatorList.includes(loggedInUser)){
            //logged in user is moderator
            if(loggedInUser === uid){
                this.props.toast('Cannot perform action on self!', 'error');
                return;
            }
            this.props.firestore.collection('groups').doc(this.state.groupToShow).update({
                requestList: firebase.firestore.FieldValue.arrayRemove(uid)
            }).then(() => {
                this.getCurrentGroupData(this.state.groupToShow);
                this.setState({showConfirm: false, userAction: null, userToHandle: null});
                this.props.toast('Join request denied.', 'error');
                this.getGroups();
            });
        } else {
            this.props.toast('Insufficient permissions!', 'error');
        }
    }

    /**
     * Moves user from `requestList` to `memberList`.
     * @param {String} uid 
     * @returns 
     */
    approveUser = async (uid) => {
        const loggedInUser = this.props.user.uid;
        // we need to get the current data for this group, to ensure user still has proper permissions
        const groupData = this.state.groupToShowData.data;
        if(groupData.moderatorList.includes(loggedInUser)){
            //logged in user is moderator
            if(loggedInUser === uid){
                this.props.toast('Cannot perform action on self!', 'error');
                return;
            }
            this.props.firestore.collection('groups').doc(this.state.groupToShow).update({
                requestList: firebase.firestore.FieldValue.arrayRemove(uid),
                memberList: firebase.firestore.FieldValue.arrayUnion(uid)
            }).then(() => {
                this.getCurrentGroupData(this.state.groupToShow);
                this.setState({showConfirm: false, userAction: null, userToHandle: null});
                this.props.toast('Join request approved.', 'success');
                this.getGroups();
            });
        } else {
            this.props.toast('Insufficient permissions!', 'error');
        }
    }

    /**
     * Removes user from `memberList`.
     * @param {String} uid 
     * @returns 
     */
    removeUser = async (uid) => {
        const loggedInUser = this.props.user.uid;
        // we need to get the current data for this group, to ensure user still has proper permissions
        const groupData = this.state.groupToShowData.data;
        if(groupData.moderatorList.includes(loggedInUser)){
            //logged in user is moderator
            if(loggedInUser === uid){
                this.props.toast('Cannot perform action on self!', 'error');
                return;
            }
            if(groupData.moderatorList.includes(uid)){
                // intended user is a moderator, check that the logged in user is creator before proceeding
                if(loggedInUser !== groupData.uid){
                    this.props.toast('Insufficient permissions!', 'error');
                    return;
                }
            }
            this.props.firestore.collection('groups').doc(this.state.groupToShow).update({
                memberList: firebase.firestore.FieldValue.arrayRemove(uid),
                moderatorList: firebase.firestore.FieldValue.arrayRemove(uid)
            }).then(() => {
                this.getCurrentGroupData(this.state.groupToShow);
                this.setState({showConfirm: false, userAction: null, userToHandle: null});
                this.props.toast('User removed from group.', 'error');
            })
        } else {
            this.props.toast('Insufficient permissions!', 'error');
        }
    }

    /**
     * Adds user to `moderatorList`.
     * @param {String} uid 
     * @returns 
     */
    promoteUser = async (uid) => {
        const loggedInUser = this.props.user.uid;
        // we need to get the current data for this group, to ensure user still has proper permissions
        const groupData = this.state.groupToShowData.data;
        if(loggedInUser === groupData.uid){
            //logged in user is creator
            if(loggedInUser === uid){
                this.props.toast('Cannot perform action on self!', 'error');
                return;
            }
            this.props.firestore.collection('groups').doc(this.state.groupToShow).update({
                moderatorList: firebase.firestore.FieldValue.arrayUnion(uid)
            }).then(() => {
                this.getCurrentGroupData(this.state.groupToShow);
                this.setState({showConfirm: false, userAction: null, userToHandle: null});
                this.props.toast('User promoted to moderator.', 'success');
            })
        } else {
            this.props.toast('Insufficient permissions!', 'error');
            return;
        }
    }

    /**
     * Removes user from `moderatorList`.
     * @param {String} uid 
     * @returns 
     */
    demoteUser = async (uid) => {
        const loggedInUser = this.props.user.uid;
        // we need to get the current data for this group, to ensure user still has proper permissions
        const groupData = this.state.groupToShowData.data;
        if(loggedInUser === groupData.uid){
            //logged in user is creator
            if(loggedInUser === uid){
                this.props.toast('Cannot perform action on self!', 'error');
                return;
            }
            this.props.firestore.collection('groups').doc(this.state.groupToShow).update({
                moderatorList: firebase.firestore.FieldValue.arrayRemove(uid)
            }).then(() => {
                this.getCurrentGroupData(this.state.groupToShow);
                this.setState({showConfirm: false, userAction: null, userToHandle: null});
                this.props.toast('User demoted to member.', 'error');
            })
        } else {
            this.props.toast('Insufficient permissions!', 'error');
            return;
        }
    }

    handleUserAction = (action, uid, msg) => {
        var groupName = this.state.groupToShowData.data.name;
        var username = null;
        for(let i = 0; i < this.state.groupToShowData.memberList.length; i++){
            if(this.state.groupToShowData.memberList[i].uid == uid){
                username = this.state.groupToShowData.memberList[i].username;
            }
        }
        if(username == null){
            //not in member list, let's check request list
            for(let i = 0; i < this.state.groupToShowData.requestList.length; i++){
                if(this.state.groupToShowData.requestList[i].uid == uid){
                    username = this.state.groupToShowData.requestList[i].username;
                }
            }
        }
        msg = msg.replace('{username}', username).replace('{group}', groupName);

        this.setState({
            userAction: action,
            userToHandle: uid,
            showConfirm: true,
            confirmMessage: msg ?? 'Are you sure you want to perform this action?'
        });
    }

    render() {
        return(
            <>
                <Container className="card-large mx-auto mb-3">
                    <Row>
                        <Col className="col-12 col-xl-7">
                            <Collapse 
                                title={`Group List (${this.state.joinedGroups.length})`}
                                open={true} 
                                titleClass="lead mx-2" 
                                titleContainerClass="rounded-top" 
                                containerClass="bg-dark text-white mt-3 my-lg-3 mx-0 rounded" 
                                className="p-2 bg-collapse rounded-bottom"
                            >
                                <GroupList
                                    handleDelete={(e) => this.handleDelete(e.target.id)}
                                    handleView={(e) => this.handleView(e.target.id)}
                                    handleLeave={(e) => this.handleLeave(e.target.id)}
                                    handleLeaderboard={(e) => this.props.history.push(`/groups/${e.target.id}/leaderboard`)}
                                    joinedGroups={this.state.joinedGroups}
                                    uid={this.props.user.uid}
                                />
                            </Collapse>
                        </Col>
                        <Col className="col-12 col-xl-5">
                            <Collapse 
                                title={`Requested (${this.state.pendingGroups.length})`} 
                                titleClass="lead mx-2" 
                                titleContainerClass="rounded-top" 
                                containerClass="bg-dark text-white my-3 mx-0 rounded" 
                                className="p-2 bg-collapse rounded-bottom"
                                open={true}
                            >
                                <RequestList
                                    handleCancel={(e) => this.handleCancel(e.target.id)}
                                    pendingGroups={this.state.pendingGroups}
                                />
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
                <Container className="card-large mx-auto mb-3">
                        <Row>
                            <Col className="col-12 col-md-6 p-4">
                            <h2 className="h3">Join a group</h2>
                            <div className="form-group">
                                <label htmlFor="groupCode" className="sr-only">Invite Code</label>
                                <input type="text" id="groupCode" className="form-control mt-3 bg-light text-dark" placeholder="Invite Code or URL" value={this.state.joinCode} onChange={(e) => {this.setState({joinCode: e.target.value.toString()})}} />
                                <button className="submit-button btn btn-primary btn-block mt-3" onClick={this.submitJoinRequest}>Join Group</button>
                            </div>
                        </Col>
                        <Col className="col-12 col-md-6 p-4">
                            <h2 className="h3">Create new group</h2>
                            {!this.state.hideCreate ? (
                            <div className="form-group">
                                <div className="input-group mt-3">
                                    <label htmlFor="groupName" className="sr-only">Group Name</label>
                                    <input type="text" id="groupName" className="form-control bg-light text-dark rounded-left" placeholder="Group Name" value={this.state.newGroupName} disabled={true} />
                                    <div className="input-group-append">
                                        <button onClick={this.generateRandomName} className="btn btn-secondary">Generate</button>
                                    </div>
                                </div>
                                <button className="submit-button btn btn-primary btn-block mt-3" onClick={this.submitNewGroup}>Create Group</button>
                            </div>
                            ) : this.state.createdGroups.length >= this.state.max_groups ? (<p>You have created the maximum number of groups.</p>): (<p>Your group has been created!</p>)
                            }
                        </Col>
                    </Row>
                </Container>
                <Container className="card-large mx-auto mb-3">
                    <Row>
                        <Col className="col-12 p-4">
                            <h2 className="h4 mb-3" id="faqs">Frequently Asked Questions</h2>
                            <Collapse title="How do groups work?" className="p-3">
                                Once a group is created, the group invite link can be shared with others. Once they request to join, the creator (as well as any moderators they've added) will be able to approve them. Once approved, the member will be added into the group and appear on the group leaderboard.
                            </Collapse>
                            <Collapse title="How do I join a group?" className="p-3">
                                Follow the invite link and you will be prompted to join the applicable group. 
                                You can also paste the URL or invite code directly into the <span className="font-weight-bold">Join a group</span> field to submit your request.
                            </Collapse>
                            <Collapse title="How do I create a group?" className="p-3">
                                If you haven't already reached the maximum number of created groups, you can use the <span className="font-weight-bold">Create a group</span> form to generate a random group name. Once the group is submitted, you will see it among your group list along with the invite link.
                            </Collapse>
                            <Collapse title="How many groups can I create? How many can I join?" className="p-3">
                                You can create up to a maximum of 5 groups. There is no limit on the number of groups you can join.
                            </Collapse>
                            <Collapse title="How do I add a moderator? What are they able to do?" className="p-3">
                                If you are the creator of a group, you can add anyone as a moderator by clicking the <span className="font-weight-bold">Manage</span> dropdown next to their name in the member list while viewing the group, then click the <span className="font-weight-bold">Promote</span> button. Once promoted, the user will be able to approve incoming join requests and remove any users that don't have elevated permissions (e.g., other moderators and the creator). If you need to remove these permissions, use the <span className="font-weight-bold">Demote</span> button within the same management menu.
                            </Collapse>
                            <Collapse title="Is there a limit for the number of members a group has?" className="p-3">
                                There is currently no limit on the number of users that can join a single group.
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
                { this.state.cancelRequest != null ?  
                        <Modal show={true} onHide={() => this.closeModal(false)} animation={false}>
                            <Modal.Header>
                                <Modal.Title className="text-white">Cancel Request Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>You are about to cancel your request to join the following group:</p>
                                <p className="font-weight-bold">{this.getGroupData(this.state.cancelRequest).name}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="secondary-button btn btn-secondary" onClick={() => this.closeModal(false)}>Close</button>
                                <button className="danger-button btn btn-danger" onClick={this.cancelJoinRequest}>Cancel Request</button>
                            </Modal.Footer>
                        </Modal>
                : 
                    null
                }

                { this.state.deleteGroup != null ?  
                        <Modal show={true} onHide={() => this.closeModal(true)} animation={false}>
                            <Modal.Header>
                                <Modal.Title className="text-white">Delete Group Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>You are about to delete the following group:</p>
                                <p className="font-weight-bold">{this.getGroupData(this.state.deleteGroup).name}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="secondary-button btn btn-secondary" onClick={() => this.closeModal(true)}>Close</button>
                                <button className="danger-button btn btn-danger" onClick={this.deleteCreatedGroup}>Delete Group</button>
                            </Modal.Footer>
                        </Modal>
                : 
                    null
                }

                { this.state.leaveGroup != null ?  
                        <Modal show={true} onHide={() => this.closeModal(true)} animation={false}>
                            <Modal.Header>
                                <Modal.Title className="text-white">Exit Group Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>You are about to leave the following group:</p>
                                <p className="font-weight-bold">{this.getGroupData(this.state.leaveGroup).name}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="secondary-button btn btn-secondary" onClick={() => this.closeModal(true)}>Close</button>
                                <button className="danger-button btn btn-danger" onClick={this.leaveJoinedGroup}>Leave Group</button>
                            </Modal.Footer>
                        </Modal>
                : 
                    null
                }

                { this.state.groupToShow != null && !this.state.showJoinModal ?
                    <Modal show={!this.state.showConfirm && this.state.leaveGroup == null && this.state.deleteGroup == null} onHide={() => this.closeModal(false)} size="lg" backdrop="static" scrollable={true} animation={false}>
                        <Modal.Header>
                            <Modal.Title className="text-white"><h3 className="h4">{this.state.groupToShowData != null ? this.state.groupToShowData.data.name : null}</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <GroupViewer
                                groupInformation={this.state.groupToShowData}
                                groupId={this.state.groupToShow}
                                uid={this.props.user.uid}
                                firestore={this.props.firestore}
                                history={this.props.history}
                                handleLeave={(e) => this.setState({leaveGroup: this.state.groupToShow})}
                                handleDelete={(e) => this.setState({deleteGroup: this.state.groupToShow})}
                                handleApprove={(e) => this.handleUserAction(this.approveUser, e.target.id, 'Are you sure you want to approve {username} joining {group}?')}
                                handleDeny={(e)=> this.handleUserAction(this.denyUser, e.target.id, 'Are you sure you want to deny {username} from joining {group}?')}
                                handleRemove={(e) => this.handleUserAction(this.removeUser, e.target.id, 'Are you sure you want to remove {username} from {group}?')}
                                handlePromote={(e)=> this.handleUserAction(this.promoteUser, e.target.id, 'Are you sure you want to promote {username} to a moderator for {group}?')}
                                handleDemote={(e) => this.handleUserAction(this.demoteUser, e.target.id, 'Are you sure you want to demote {username} to a regular user in {group}?')}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="secondary-button btn btn-secondary" onClick={() => this.closeModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                :
                    null
                }

                { this.state.showJoinModal ? 
                    <Modal show={true} onHide={() => this.closeModal(false)} backdrop="static" animation={false}>
                        <Modal.Header>
                            <Modal.Title className="text-white">
                                <h3 className="h4">
                                    Join Group
                                </h3>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You are about to request to join the following group:</p>
                            <p className="font-weight-bold">{this.state.joinGroupName}</p>
                        </Modal.Body>
                        <Modal.Footer>
                                <button className="secondary-button btn btn-secondary" onClick={() => this.closeModal(false)}>Close</button>
                                <button className="submit-button btn btn-primary" onClick={this.submitJoinRequest}>Confirm</button>
                        </Modal.Footer>
                    </Modal>
                : null}

                { this.state.showAlreadyJoinedModal ? 
                    <Modal show={true} onHide={() => this.closeModal(false)} backdrop="static" animation={false}>
                        <Modal.Header>
                            <Modal.Title className="text-white">
                                <h3 className="h4">
                                    Join Request Pending
                                </h3>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Your request to join <span className="font-weight-bold mx-1">{this.state.joinGroupName}</span> is pending.</p>
                            <p>The group will automatically be added to your group list once the request is approved.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="secondary-button btn btn-secondary" onClick={() => this.closeModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                : null}

                { this.state.showConfirm ? 
                    <Modal show={true} animation={false}>
                        <Modal.Header>
                            <Modal.Title className="text-white">
                                <h3 className="h4">
                                    Confirmation
                                </h3>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{this.state.confirmMessage}</p>
                        </Modal.Body>
                        <Modal.Footer>
                        <button className="secondary-button btn btn-secondary" onClick={()=>{this.setState({showConfirm: false, userAction: null, userToHandle: null})}}>Cancel</button>
                            <button className="submit-button btn btn-primary" onClick={() => this.state.userAction(this.state.userToHandle)}>Confirm</button>
                        </Modal.Footer>
                    </Modal>
                : null }

            </>
        )
    }
}

/**
 * Generates a list of groups a user is a member of.
 * @param {*} props 
 * @returns 
 */
function GroupList(props){
    /**
     * Compiles the joined groups list, alphabetizes, and adds buttons for
     * group functions available to user.
     * @param {Object} joined 
     * @param {String} uid 
     * @returns {Object}
     */
    function compileGroupList(joined, uid){
        var groupList = [];
        var groupObj = {};
        // go through joined
        for(let i = 0; i < joined.length; i++){
            let doc = joined[i].data();
            groupObj[joined[i].id] = {
                    id: joined[i].id,
                    name: doc.name,
                    created: doc.uid === uid,
                    moderated: doc.moderatorList.includes(uid),
                    members: doc.memberList.length,
                    pendingRequests: doc.requestList.length > 0,
                    numRequests: doc.requestList.length
                }
            }
        for(let i = 0; i < Object.keys(groupObj).length; i++){
            var data = groupObj[Object.keys(groupObj)[i]];
            groupList.push(data);
        }

        //alphabetize group list
        groupList.sort((a, b) => {
            return a.name.localeCompare(b.name)
        });

        return groupList;
    }

    const { joinedGroups, handleDelete, handleLeave, handleLeaderboard, handleView, uid } = props;
    var groupList = compileGroupList(joinedGroups, uid);
    return (<>
        <Container>
        {groupList.length > 0 ? groupList.map((data) =>(
            <Row key={data.id} className="py-2 text-white">
                <Col className="col-12 col-md-7 mb-2 mb-lg-0 mt-2">
                    <a id={data.id} onClick={handleView} className="group-link">{data.name}</a> ({data.members})
                    {(data.moderated || data.created) && data.pendingRequests ? <span className="badge badge-danger badge-sm ml-2 align-top">{data.numRequests}</span> : null}
                </Col>
                <Col className="col-12 col-md-5 text-md-right">
                    <button className="secondary-button btn btn-small btn-primary mx-lg-2 my-lg-0" onClick={handleView} id={data.id}>View</button>
                    <button className="submit-button btn btn-small btn-primary mx-2 my-lg-0" onClick={handleLeaderboard} id={data.id}>Leaderboard</button>
                </Col>
            </Row>
        )) : 
            <Row>
                <Col className="col-12">
                    <p className="mt-3">You have not joined any groups.</p>
                </Col>
            </Row>
        }
        </Container></>
    )
}

/**
 * Generates a list of groups requested to join.
 * @param {*} props 
 * @returns 
 */
function RequestList(props){
    /**
     * Compiles the requested groups list, alphabetizes, and adds buttons for
     * group functions available to user.
     * @param {Object} requested 
     * @returns 
     */
    function compileRequested(requested){
        var requestList = [];
        for(let i = 0; i < requested.length; i++){
            let doc = requested[i].data();
            requestList.push({
                id: requested[i].id,
                name: doc.name,
                members: doc.memberList.length
            })
        }

        //alphabetize request list
        requestList.sort((a, b) => {
            return a.name.localeCompare(b.name)
        });

        return requestList;
    }
    const { pendingGroups, handleCancel, handleView } = props;
    var requestList = compileRequested(pendingGroups);
    return (<>
        <Container>
            {requestList.length > 0 ? requestList.map((data) => (
                <Row key={data.id} className="py-2 text-white">
                    <Col className="col-12 col-sm-7 col-lg-8 mb-2 mb-lg-0 mt-2">
                        {data.name}
                    </Col>
                    <Col className="col-12 col-sm-5 col-lg-4 text-lg-right">
                        <button className="danger-button btn btn-block btn-danger my-2 my-lg-0" onClick={handleCancel} id={data.id}>Cancel</button>
                    </Col>
                </Row>
            )) : 
            <Row>
                <Col className="col-12">
                    <p className="mt-3">No pending group requests.</p>
                </Col>
            </Row>                
            }
        </Container>
    </>)
}

/**
 * Renders a list of requests and members for a group along with moderation buttons.
 * @param {*} props 
 * @returns 
 */
function GroupViewer(props){
    const { groupInformation, groupId, uid, handleLeave, handleDelete, handleApprove, handleDeny, handleRemove, handlePromote, handleDemote, history, firestore } = props;
    if(groupInformation != null){
        const creator = groupInformation.data.uid;
        const { moderatorList } = groupInformation.data;
        return (<>
            <Container>
                <Row className="px-2 py-4 mt-2 mb-3 mx-0 rounded bg-dark border-dark">
                    <Col className="col-12 text-white">
                        <p className="lead">Invite Link</p>
                    </Col>
                    <Col className="col-12">
                        <div className="input-group">
                                <input type="text" className="form-control bg-light text-dark rounded-left" value={ 'https://monsoonfantasy.arizona.edu/groups/' + groupId } disabled={true} />
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" onClick={() => { navigator.clipboard.writeText('https://monsoonfantasy.arizona.edu/groups/' + groupId) }}>Copy</button>
                                </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <button onClick={() => history.push(`/groups/${groupId}/leaderboard`)} className="submit-button btn btn-block btn-primary mb-3">Leaderboard</button>
                    </Col>
                </Row>
                { moderatorList.includes(uid) && groupInformation.requestList.length > 0 ? 
                    <Row>
                        <Col>
                        <Collapse
                            title={`Requests (${groupInformation.requestList.length})`}
                            titleContainerClass="rounded-top" 
                            containerClass="bg-dark text-white mx-0 mb-3 rounded" 
                            className="p-3 bg-collapse rounded-bottom"
                            open={groupInformation.requestList.length > 0}
                        >
                            {groupInformation.requestList.length > 0 ? groupInformation.requestList.map((user) => (
                            <Row key={user.uid}>
                                <Col className="col-12 col-md-7">
                                    <p className="m-0 mb-2 ml-lg-2">{user.username}</p>
                                </Col>
                                <Col className="col-12 col-md-5 text-md-right">
                                    <button onClick={handleApprove} className="submit-button btn btn-sm btn-primary ml-lg-2 mb-0" id={user.uid}>Approve</button>
                                    <button onClick={handleDeny} className="danger-button btn btn-sm btn-danger ml-2 mb-0" id={user.uid}>Deny</button>
                                </Col>
                                { groupInformation.requestList.length > 1 && groupInformation.requestList[groupInformation.requestList.length-1].uid != user.uid ? 
                                    <Col className="col-12">
                                        <hr className="border-secondary my-2" />
                                    </Col> : null }
                            </Row>

                        )) :
                            <Col>No pending requests.</Col>}
                        </Collapse>
                        </Col>
                    </Row>
                : null }
                <Row>
                    <Col>
                    <Collapse 
                        title={`Members (${groupInformation.memberList.length})`}
                        titleContainerClass="rounded-top" 
                        containerClass="bg-dark text-white mx-0 mb-3 rounded" 
                        className="p-3 bg-collapse rounded-bottom"
                        open={true}
                    >
                        {groupInformation.memberList.map((user) => (
                            <Row key={user.uid} className="mb-1">
                                <Col className="col-12 col-lg-8">
                                    <p className="p-0 my-0 ml-1 py-lg-2" style={{color: uid === user.uid ? '#3cbc74' : null }}>
                                        {user.username}
                                        { creator === user.uid ? (<span className="badge badge-light ml-2">creator</span>) : null}
                                        { moderatorList.includes(user.uid) && creator !== user.uid ? (<span className="badge badge-light ml-2">moderator</span>) : null}
                                        { user.uid === uid ? (<span className="badge badge-light ml-2">you</span>) : null} 
                                    </p>
                                </Col>
                                <Col className="col-12 col-lg-4">
                                { 
                                    user.uid != creator // viewed user not creator
                                    && moderatorList.includes(uid) // viewing user is in moderator list
                                    && user.uid != uid // viewed user not self
                                    && (!moderatorList.includes(user.uid) || creator == uid) 
                                    ?
                                    <Collapse title="Manage" containerClass="bg-dark text-white my-2 my-lg-0" titleClass="small px-2" titleContainerClass="border-dark py-2" className="px-3" thin={true}>
                                        { creator === uid && moderatorList.includes(user.uid) && user.uid !== uid ? <button onClick={handleDemote} className="danger-button btn btn-sm btn-danger btn-block" id={user.uid}>Demote</button> : null}
                                        { creator === uid && !moderatorList.includes(user.uid) ? <button onClick={handlePromote} className="submit-button btn btn-sm btn-primary btn-block" id={user.uid}>Promote</button> : null}
                                        { moderatorList.includes(uid) && creator !== user.uid 
                                            && (!moderatorList.includes(user.uid) || creator === uid)
                                            ? <button onClick={handleRemove} className="danger-button btn btn-sm btn-danger btn-block" id={user.uid}>Remove</button> : null 
                                        }  
                                    </Collapse>
                                : null }
                                </Col>
                                { groupInformation.memberList.length > 1 && groupInformation.memberList[groupInformation.memberList.length -1].uid != user.uid ? 
                                    <Col className="col-12">
                                        <hr className="border-secondary my-2" />
                                    </Col> : null }
                            </Row>
                        ))}
                    </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Collapse 
                        title={ uid == creator ? "Group Management" : "Membership Options"}
                        titleContainerClass="rounded-top" 
                        containerClass="bg-dark text-white mx-0 mb-3 rounded" 
                        className="pt-3 pb-2 px-3 bg-collapse rounded-bottom"
                    >
                        { uid == creator ? 
                                <button onClick={handleDelete} className="btn btn-danger btn-block danger-button">Delete Group</button>
                            : 
                                <button onClick={handleLeave} className="btn btn-danger btn-block danger-button">Leave Group</button> 
                        }
                    </Collapse>
                    </Col>
                </Row>
            </Container>    
        </>)
    } else {
        return (<>
            <div className="text-center"><Spinner animation="border" role="status" variant="light" /></div>
        </>)
    }
}